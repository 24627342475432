import { postBodyRequest,getRequest } from '@/utils/axios'
// 小程序用户分页
export function page (params) {
  return postBodyRequest('/market/applet-back/public/page', params)
}
// 企业认证
export function userReceive (params) {
  return postBodyRequest('/market/applet-back/public/receive', params)
}
// 设置推广类型
export function compulsoryAllocate (params) {
  return postBodyRequest('/market/applet-back/public/compulsoryAllocate', params)
}

// 关联订单到小程序订单
export function relatedOrderToAppletOrder (params) {
  return postBodyRequest('/market/applet-back/public/related-order-applet', params)
}

export function appletPage (params) {
  return postBodyRequest('/market/applet/user-c-order/relate-applet-user/page', params)
}
export function deleteApple (params) {
  return getRequest('/market/applet/user-c-order/relate-applet-user/delete', params)
}
