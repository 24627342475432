import { postBodyRequest } from '@/utils/axios'

// 小程序询价单分页
export function page (params) {
  return postBodyRequest('/market/applet-back/c-order/inquiry-sheet/page', params)
}

// 小程序个人询价单分页
export function detailsPage (params) {
  return postBodyRequest('/market/applet-back/c-order/inquiry-sheet/details/page', params)
}
// 确认跟进
export function follow (params) {
  return postBodyRequest('/market/applet-back/c-order/inquiry-sheet/follow', params)
}
