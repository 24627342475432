import {
  getRequest,
  postBodyRequest
} from '@/utils/axios'

export function kanbanVehicle (params) {
  return postBodyRequest('/trans/kanban/vehicle', params)
}

// 质量标准-新增前的详情渲染数据
export function getPreviousData (customerId, gradeType, params) {
  return getRequest(`/market/customer/rate/detail/${customerId}/${gradeType}`, params)
}
// 质量标准-历史评级分页
export function historyPage (params) {
  return postBodyRequest('/market/customer/rate/page', params)
}
// 质量标准-新增编辑
export function saveData (params) {
  return postBodyRequest('/market/customer/rate/save', params)
}
// 质量标准-详情
export function getDetailData (rateId, params) {
  return getRequest(`/market/customer/rate/detail/${rateId}`, params)
}
// 私海客户看板
export function privateKanBan (params) {
  return postBodyRequest('/anal/customer/private/real-time', params)
}

// 私海客户--提醒列表
export function privateRemindPage (params) {
  return postBodyRequest('/anal/customer/remind/page', params)
}

// 合作客户看板
export function coopKanBan (params) {
  return postBodyRequest('/anal/customer/coop/real-time', params)
}
