import { postBodyRequest } from '@/utils/axios'

export function getListData (customerId, params) {
  return postBodyRequest(`/market/crm/emp/${customerId}/page`, params)
}

export function add (params) {
  return postBodyRequest('/market/crm/emp/add', params)
}

export function deleteSalesman (id, params) {
  return postBodyRequest(`/market/crm/emp/${id}/delete`, params)
}
