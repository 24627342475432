import { postBodyRequest } from '@/utils/axios'

// 客户下单列表
export function page (params) {
  return postBodyRequest('/trans/applet/c-order/customer-sales-page', params)
}

// 小程序订单接单
export function receive (params) {
  return postBodyRequest('/trans/applet/c-order/receive', params)
}

// 用户小程序下单列表
export function userPage (params) {
  return postBodyRequest('/market/applet-back/c-order/page', params)
}

// 客户下单统计
export function customerSalesStatistics (params) {
  return postBodyRequest('/trans/applet/c-order/customer-sales-statistics', params)
}
