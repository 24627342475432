import { postBodyRequest } from '@/utils/axios'

export function getPageList (params) {
  return postBodyRequest('/market/crm/cooper/page', params)
}

export function editCustomerTag (params) {
  return postBodyRequest('/market/crm/cooper/tag', params)
}

export function cooperTransfer (params) {
  return postBodyRequest('/market/crm/cooper/transfer', params)
}

export function backPrivate (params) {
  return postBodyRequest('/market/crm/cooper/back-private', params)
}
