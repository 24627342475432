<template>
  <div>
    <a-row :gutter="[24,24]" justify="end">
      <a-col :span="6">
        <div class="end">
          <a-button class="searchLoading-button" type="primary" @click="onEdit(null,0)">
            新建对接人
          </a-button>
        </div>
      </a-col>

    </a-row>
    <a-table class="tool-m-t-10" size="small" :columns="columns" :row-key="record => record.customerId" :data-source="listData" bordered :pagination="pagination" :loading="loading" :scroll="{ x: 200}" @change="handleTableChange">
      <template #operation="{ record }">
        <span>
          <a @click="onEdit(record,1)">编辑</a>
        </span>
      </template>
    </a-table>

    <a-modal v-model:visible="show" width="50%" :title="editType===0?'新增对接人':'修改对接人'" @ok="editMethod">
      <a-form layout="vertical" ref="formRef" :model="editForm" :rules="rules">
        <a-row :gutter="[24,48]">
          <a-col :span="12">
            <a-form-item label="联系人名称" name="name">
              <a-input v-model:value="editForm.name" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系人电话" name="mobile">
              <a-input v-model:value="editForm.mobile" type="number" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="身份证">
              <a-input v-model:value="editForm.certificateNumber" />
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="是否主联系人">
              <a-select allowClear v-model:value="editForm.isMain.value" class="ransport_search_input">
                <a-select-option v-for="item in $store.state.enumAll.BooleanFlagEnum" :key="item.value"> {{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :span="24">
            <a-form-item label="备注">
              <a-textarea v-model:value="editForm.remark" :maxlength="200" />
            </a-form-item>
          </a-col>
          <a-col :span="24"></a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { mobileValidate } from '@/utils/validate'
import { contactCustomerIdPage, add, edit } from '@/api/crmManagement/contact'
import { message } from 'ant-design-vue'
export default {
  props: {
    customerId: String
  },
  setup (props, context) {
    const formRef = ref()
    const state = reactive({
      editForm: {},
      editType: -1,
      cid: props.customerId,
      listData: [],
      loading: false,
      show: false,
      columns: [
        {
          title: '联系人名称',
          dataIndex: 'name'
        },
        {
          title: '联系人电话',
          dataIndex: 'mobile'
        },
        {
          title: '身份证号',
          dataIndex: 'certificateNumber'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        name: { required: true, message: '请填写联系人名称', trigger: 'blur' },
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
      },
      pagination: {
        customerId: props.customerId,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: 1,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }

    })
    const loadData = () => {
      state.loading = true
      contactCustomerIdPage(state.cid, {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total

          // console.log(state.data)
        }
      }).catch(err => {
        console.log('分页错误', err)
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(loadData)
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onEdit = (e, type) => {
      if (type === 1) {
        state.editForm = e
        state.editType = 1
      } else {
        state.editType = 0
        state.editForm = {
          id: null,
          customerId: props.customerId
        }
      }
      state.show = true
    }
    const editMethod = () => {
      formRef.value.validate()
        .then(() => {
          if (state.editType === 0) {
            add({ customerContact: state.editForm }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                loadData()
                state.show = false
              }
            })
          } else if (state.editType === 1) {
            edit({ customerContact: state.editForm }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                loadData()
                state.show = false
              }
            })
          } else {
            message.error('请刷新后再试')
          }
        }).catch(err => { console.log(err) })
    }

    return {
      ...toRefs(state),
      loadData,
      formRef,
      handleTableChange,
      onEdit,
      editMethod
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../tool/index.less';
@import './index.less';
</style>
