<template>
  <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">
    <template #title>
      <span class="title">新增</span>
    </template>
    <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
      <a-form-item>
        <template #label>
          <div><span style="color:#f00">* </span>客户类型</div>
        </template>
        <a-select v-model:value="addForm.customerType.value" @change="customerTypeChange" :options="CustomerTypeEnum" placeholder="请选择客户类型">
        </a-select>
      </a-form-item>
      <a-form-item label="业务类型" v-show="addForm.customerType.value !== 1">
        <a-radio-group name="businessType" v-model:value="addForm.businessType">
          <template v-for="item in businessTypes" :key="item.value">
            <a-radio-button :value="item.value">{{item.label}}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-show="addForm.customerType.value === 2" label="企业名称" name="name" :rules="{ required: true, message: '请填写企业名称', trigger: 'change',type:'string' }">
        <a-select v-model:value="addForm.name" placeholder="请输入企业名称" :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
          <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
            <span>{{ item.name }}</span>
            <span style="float:right;">{{ item.operName }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="客户姓名" name="name">
        <a-input v-model:value="addForm.name" placeholder="请填写客户姓名" />
      </a-form-item>
      <a-form-item label="客户电话" name="mobile">
        <a-input v-model:value="addForm.mobile" placeholder="请填写客户电话" />
      </a-form-item>
      <a-form-item label="客户微信">
        <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
      </a-form-item>
      <a-form-item label="客户地址">
        <a-input v-model:value="addForm.address" placeholder="请填写客户地址" />
      </a-form-item>
      <a-form-item name="certificateNumber">
        <template #label>
          {{addForm.customerType.value === 2 ? '企业信用代码' : '客户身份证'}}
        </template>
        <a-input v-model:value="addForm.certificateNumber" placeholder="请填写" />
      </a-form-item>
      <a-form-item label="结算方式" name="settlementMode">
        <a-select allowClear v-model:value="addForm.settlementMode" :options="CustomerSettlementEnum" placeholder="请选择结算方式">
        </a-select>
      </a-form-item>
      <a-form-item label="客户来源">
        <a-select allowClear v-model:value="addForm.customerSourceId" :options="$store.state.enumAll.customerSource" placeholder="选择客户来源">
        </a-select>
      </a-form-item>
      <a-form-item label="线索类型">
        <a-select allowClear v-model:value="addForm.clueType" :options="$store.state.enumAll.ClueTypeEnum" placeholder="请选择线索类型">
          <a-select-option v-for="item in $store.state.enumAll.ClueTypeEnum" :key="item.value"> {{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="渠道来源">
        <a-cascader v-model:value="addForm.addChannels" :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" placeholder="选择渠道" />
      </a-form-item>
      <a-form-item>
        <template #label>
          <div>
            <span>客户标签 </span>
            <a style="font-size:12px" @click="addForm.tagId = ''"> 重置选择</a>
          </div>
        </template>
        <a-radio-group v-model:value="addForm.tagId" button-style="solid">
          <a-space>
            <a-radio-button v-for="item in $store.state.enumAll.CustomerType" :key="item.value" :value="item.value">{{item.label}}</a-radio-button>
          </a-space>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { add } from '@/api/crmManagement/privateSea'
import { numberValidate, enterpriseValidate, idNumberValidate } from '@/utils/validate'
import { enterprise } from '@/api/system/setting'
import { useStore } from 'vuex'

import { message } from 'ant-design-vue'
export default {
  setup () {
    const store = useStore()
    const formRef = ref()
    const state = reactive({
      CustomerTypeEnum: [],
      CustomerSettlementEnum: [],
      addForm: {
        name: '',
        mobile: '',
        wechat: '',
        customerType: { value: 1, label: '个人' }
      },
      businessTypes: [
        { label: '同行业务', value: 2 },
        { label: '大客户业务', value: 3 },
        { label: '平台业务', value: 4 }
      ],
      customerSource: [],
      enterprises: [],
      customerType: false,
      searchAddShow: false,
      rules: {
        name: { required: true, message: '请填写客户名称', trigger: 'blur' },
        customerType: { required: true, message: '请选择客户类型', trigger: 'change', type: 'number' },
        settlementMode: { required: true, message: '请选择结算方式', trigger: 'change', type: 'number' },
        certificateNumber: [{ required: false, message: '请填写身份证号码', trigger: 'blur' }, { pattern: idNumberValidate, message: '请填写正确的身份证号码', trigger: 'blur' }],
        mobile: [{ required: false, message: '请填写电话号码', trigger: 'blur' }, { pattern: numberValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
        // clueType: { required: true, message: '请选择线索类型', trigger: 'change', type: 'number' },
        // addChannels: { required: true, message: '请选择渠道来源', trigger: 'change', type: 'array' }
      }
    })
    const loadData = () => {
      state.searchAddShow = true
      state.addForm = {
        name: '',
        mobile: '',
        wechat: '',
        customerType: { value: 1, label: '个人' }
      }
    }

    // 客户类型选择
    const customerTypeChange = (e, v) => {
      state.addForm.customerType = v
      if (state.addForm.customerType.value === 2) {
        state.rules.certificateNumber[1].pattern = enterpriseValidate
        state.rules.certificateNumber[1].message = '请输入正确的企业信用代码'
      } else {
        state.rules.certificateNumber[1].pattern = idNumberValidate
        state.rules.certificateNumber[1].message = '请输入正确的身份证号'
      }
    }
    // 新增
    const onAddForm = () => {
      if (state.addForm.mobile !== '' || state.addForm.wechat !== '') {
        formRef.value.validate()
          .then(() => {
            const channelArr = state.addForm.addChannels
            if (channelArr !== undefined && channelArr !== null && channelArr.length > 1) {
              state.addForm.channelId = channelArr[0]
              state.addForm.channelSubId = channelArr[1]
            }
            delete state.addForm.addChannels
            add({ customer: state.addForm }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                loadData()
                state.searchAddShow = false
              }
            })
          }).catch(err => { console.log(err) })
      } else message.error('微信号和电话必须填写其中一个')
    }
    // 新增-企业客户列表获取
    const entpSearch = async val => {
      if (val.length > 3) {
        const filterVal = val.replace(new RegExp('[a-z]', 'ig'), '').replace(new RegExp('[0-9]', 'g'), '')
        if (filterVal.length < 4) {
          return
        }
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          enterprise({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    const entpSelect = async (val, option) => {
      state.addForm.name = val
      state.addForm.certificateNumber = option.key
    }

    onMounted(() => {
      setTimeout(() => {
        state.customerSource = store.state.enumAll.customerSource
      }, 200)
      state.CustomerTypeEnum = JSON.parse(JSON.stringify(store.state.enumAll.CustomerTypeEnum))
      state.CustomerSettlementEnum = JSON.parse(JSON.stringify(store.state.enumAll.CustomerSettlementEnum))
      if (state.CustomerTypeEnum[0].value === '') state.CustomerTypeEnum.splice(0, 1)
      if (state.CustomerSettlementEnum[0].value === 0) state.CustomerSettlementEnum.splice(0, 1)
    })

    return {
      ...toRefs(state),
      formRef,
      entpSearch,
      entpSelect,
      loadData,
      customerTypeChange,
      onAddForm
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
