<template>
  <div>
    <!-- <a-col :span="6">
        <div class="end">
          <a-button class="searchLoading-button" type="primary" @click="onAdd">
            新增
          </a-button>
        </div>
      </a-col> -->
    <a-table v-if="listData.length > 0" size="small" :columns="columns" :row-key="record => record.customerId" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <span>
          <a @click="onEdit(record)">编辑</a>
        </span>
      </template>
      <template #totalPrice="{ record }">
        <a v-if="record.show===false" @click="onTotalPrice(record)">
          {{record.totalPrice}}
        </a>
        <div v-if="record.show===true">
          <a-space>
            <a-input v-model:value="record.totalPrice" style="margin: -5px 0" />
            <a @click="onEditTotalPrice(record)">确认</a>
          </a-space>
        </div>
      </template>
    </a-table>

    <a-modal v-model:visible="show" width="50%" title="编辑" @ok="onOK">
      <a-form layout="vertical" ref="formRef" :model="formState">
        <a-row :gutter="[24,24]">
          <a-col :span="12">
            <a-form-item label="线索分类">
              <a-select disabled v-model:value="editForm.clueType.value" class="ransport_search_input" :options="$store.state.enumAll.ClueTypeEnum">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="主叫-中继号">
              <a-input v-model:value="editForm.relayNumber" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="起点">
              <a-input v-if="startAddressStatus" v-model:value="editForm.startAddress" :disabled="true" />
              <a-cascader v-else :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="请输入起点" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="终点">
              <a-input v-if="endAddressStatus" v-model:value="editForm.endAddress" :disabled="true" />
              <a-cascader v-else :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" placeholder="请输入终点" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否有效">
              <a-select allowClear v-model:value="editForm.isEffective.value" class="ransport_search_input" @change="clueChange" :options="$store.state.enumAll.BooleanFlagEnum">
              </a-select>
              <a-modal v-model:visible="prompt" width="50%" @ok="prompt=false,editForm.isEffective.value = 0" @cancel="editForm.isEffective.value = 1" title="温馨提示">
                注意：当前线索被设置为无效，该操作会将本条线索放置公海，请确认该操作！
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col v-show="editForm.isEffective.value === 0" :span="12">
            <a-form-item label="无效标签">
              <a-select v-model:value="editForm.invalidLabelId" allowClear class="ransport_search_input" @change="invalidChange">
                <a-select-option v-for="item in labelList" :key="item.label" :value="item.value">{{item.label}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户报价">
              <a-input v-model:value="editForm.totalPrice" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="电话号码">
              <a-input v-model:value="editForm.phone" :disabled="phoneDisabled" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="网页URL">
              <a-input v-model:value="editForm.url" disabled />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="ip">
              <a-input v-model:value="editForm.ip" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="ip中文地址">
              <a-input v-model:value="editForm.ipAddress" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="QQ号码">
              <a-input v-model:value="editForm.qq" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="微信号码">
              <a-input v-model:value="editForm.wechat" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="渠道分类">
              <a-cascader v-model:value="editForm.channe" allowClear :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" @change="setChannel" placeholder="选择渠道" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="返程计划">
              <a-select allowClear v-model:value="editForm.returnPlan.value" class="ransport_search_input" :options="$store.state.enumAll.BooleanFlagEnum">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="预计运车时间">
              <a-date-picker placeholder="预计运车时间" v-model:value="editForm.transportTime" format="YYYY-MM-DD" style="width:100%" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="托运用途">
              <a-select v-model:value="editForm.transportPurpose" mode="multiple" style="width: 100%" placeholder="请选择" @change="handleChange">
                <a-select-option v-for="(item,index) in shippingList" :key="index" :value="item">{{item}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24"></a-col>
        </a-row>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { clueCustomerIdPage, getClueInfo, edit } from '@/api/crmManagement/clue'
import { getShippingUse } from '@/api/global'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'

export default {
  props: {
    customerId: String
  },
  setup (props, context) {
    const store = useStore()
    const state = reactive({
      editForm: {
        transportPurpose: '',
        clueType: { value: 0 },
        isEffective: {},
        returnPlan: {}
      },
      cid: props.customerId,
      invalid: '',
      listData: [],
      shippingList: [],
      labelList: [],
      loading: false,
      show: false,
      prompt: false,
      startAddressStatus: false,
      endAddressStatus: false,
      columns: [
        {
          title: '线索类型',
          dataIndex: 'clueType.label'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '起点',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          dataIndex: 'endAddress'

        },
        {
          title: '线索电话',
          dataIndex: 'phone'
        },
        {
          title: '线索微信',
          dataIndex: 'wechat'
        },
        {
          title: '报价',
          dataIndex: 'totalPrice',
          slots: {
            customRender: 'totalPrice'
          }
        },
        {
          title: '托运用途',
          dataIndex: 'transportPurpose'
        },
        {
          title: 'IP地址',
          dataIndex: 'ipAddress'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      phoneDisabled: true,
      pagination: {
        customerId: props.customerId,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const handleChange = (e, v) => {
      if (e[0] === '') e.splice(0, 1)
      if (e.length > 1) {
        message.error('不能选择多个用途')
        e.splice(1, 1)
      } else state.editForm.transportPurpose = e[0]
    }
    const setStartAddress = (e, v) => {
      state.editForm.startAddress = ''
      v.forEach(item => {
        state.editForm.startAddress += item.label
      })
    }
    const setEndAddress = (e, v) => {
      state.editForm.endAddress = ''
      v.forEach(item => {
        state.editForm.endAddress += item.label
      })
    }
    const loadData = () => {
      state.loading = true
      clueCustomerIdPage(state.cid, {
        customerId: state.cid,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          res.data.records.forEach(e => {
            if (e.totalPrice === null || e.totalPrice === '') {
              e.totalPrice = 0
            }
            e.show = false
          })
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total

          // console.log(state.data)
        }
      }).catch(err => {
        console.log('分页错误', err)
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(() => {
      if (props.customerId) state.cid = props.customerId
      getShippingUse({ label: '', value: 5 })
        .then(res => {
          if (res.code === 10000) state.shippingList = res.data
        }).catch(err => { console.log(err) })
      // 获取无效线索list
      setTimeout(() => { state.labelList = store.state.enumAll.InvalidClue }, 200)
      loadData()
    })
    // 无效标签change
    const invalidChange = (e, v) => {
      if (v !== undefined) {
        state.editForm.invalidLabelId = v.value
        state.editForm.invalidLabelName = v.label
      } else {
        delete state.editForm.invalidLabelId
        delete state.editForm.invalidLabelName
      }
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onEdit = (e) => {
      getClueInfo({ clueId: e.id }).then(res => {
        if (res.code === 10000) {
          if (res.data.clueType === null) res.data.clueType = {}
          state.editForm = res.data
          if (state.editForm.startAddress !== '' && state.editForm.startAddress !== null) state.startAddressStatus = true
          else state.startAddressStatus = false
          if (state.editForm.endAddress !== '' && state.editForm.endAddress !== null) state.endAddressStatus = true
          else state.endAddressStatus = false
          if (res.data.phone === null || res.data.phone === '') state.phoneDisabled = false
          state.editForm.channe = [res.data.channelId, res.data.channelSubId]
        }
      })
      state.show = true
    }
    const onOK = () => {
      edit(state.editForm).then(res => {
        if (res.code === 10000) {
          state.show = false
          message.success(res.msg)
          loadData()
        }
      })
    }

    const setChannel = (value, selectedOptions) => {
      state.editForm.channelId = value[0]
      if (value.length > 1) {
        state.editForm.channelSubId = value[1]
      }
    }

    const clueChange = (e, v) => {
      if (e === 0) state.prompt = true
      else state.prompt = false
    }

    const onTotalPrice = (e) => {
      e.show = true
    }
    const onEditTotalPrice = (e) => {
      state.editForm = e
      e.show = false
      onOK()
    }
    return {
      ...toRefs(state),
      loadData,
      handleChange,
      setStartAddress,
      setEndAddress,
      invalidChange,
      handleTableChange,
      clueChange,
      onEdit,
      setChannel,
      onOK,
      onTotalPrice,
      onEditTotalPrice
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../tool/index.less';
@import './index.less';
</style>
