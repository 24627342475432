<template>
  <div class="content">
    <a-table size="small" :columns="columns" :row-key="record => record.customerId" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #settlementCycle="{ record }">
        <div>{{record.settlementCycle}}{{record.settlementMode.value == 2?'月':'天'}}</div>
      </template>
      <template #operation="{ record }">
        <div style="display:flex;justify-content: space-between;padding:0 10px;">
          <span class="contract-status">
            <span class="status-show" :style="{color:record.contractStatus.value === 0 ? '#F6C95F' : (record.contractStatus.value === 1 ? '#00FF00' : '#f00')}">•</span>
            {{record.contractStatus.value === 2 ? '拒绝' : record.contractStatus.label}}
          </span>
          <a class="look-btn" @click="look(record)">查看</a>
          <!-- <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">
            <a class="delete-btn" style="color:#f00">删除</a>
          </a-popconfirm> -->
        </div>
      </template>
      <!-- <template #operation="{record}">
        <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">
          <a class="delete-btn" style="color:#f00">删除</a>
        </a-popconfirm>
      </template> -->
    </a-table>
    <a-modal v-model:visible="customerImgShow" width="670px" cancelText="关闭" @ok="customerImgShow=false" title="客户图片">
      <div style="display:flex">
        <a-image-preview-group>
          <a-space :size="10">
            <a-image v-for="(item,index) in fileUrls" :key="index" :width="200" :height="200" :src="item.url" />
          </a-space>
        </a-image-preview-group>
      </div>
    </a-modal>
  </div>
</template>

<script scoped>
import { onMounted, reactive, toRefs } from 'vue'
import { getListData, deleteAct, getCustomerDetail } from '@/api/crmManagement/contract'
import { message } from 'ant-design-vue'
export default {
  props: {
    customerId: String
  },
  setup (props) {
    const state = reactive({
      loading: false,
      customerImgShow: false,
      listData: [],
      fileUrls: [],
      pagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '10', '15'],
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        {
          title: '合同名称',
          dataIndex: 'contractName'
        },
        {
          title: '合同编号',
          dataIndex: 'contractCode'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          width: '20%'
        },
        {
          title: '结算方式',
          dataIndex: 'settlementMode.label',
          width: '5%'
        },
        {
          title: '结算周期',
          dataIndex: 'settlementCycle',
          width: '5%',
          slots: {
            customRender: 'settlementCycle'
          }
        },
        {
          title: '开始日期',
          dataIndex: 'contractStartTime',
          width: '7%'
        },
        {
          title: '结束日期',
          dataIndex: 'contractEndTime',
          width: '7%'
        },
        {
          title: '签约时间',
          dataIndex: 'createTime',
          width: '10%'
        },
        {
          title: '状态',
          dataIndex: 'contractStatus',
          width: '8%',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const loadData = () => {
      state.loading = true
      getListData(props.customerId, {
        customerId: props.customerId,
        current: state.pagination.current,
        size: 5
      }).then(res => {
        if (res.code === 10000) {
          state.listData = []
          state.pagination.current = res.data.current
          state.pagination.total = res.data.total
          state.listData = res.data.records
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }

    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      state.listData = []
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    const deleteData = record => {
      deleteAct(record.id)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    const look = record => {
      state.customerImgShow = true
      getCustomerDetail(record.id)
        .then(res => {
          if (res.code === 10000) {
            state.fileUrls = res.data.fileUrls
          }
        }).catch(err => { console.log(err) })
    }
    return {
      ...toRefs(state),
      handleTableChange,
      deleteData,
      look
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
