<template>
  <div style="width:100%">
      <div style="display: flex; " v-if="orderCustomerAmt && orderCustomerAmt.orderNum > 0">
          <span style="color: #0b4eaa;font-size: 16px;">累计订单量:{{orderCustomerAmt.orderNum}} </span>&emsp;
          <span style="color: #0b4eaa;font-size: 16px;margin-right: 20px;">累计台量:{{orderCustomerAmt.vehicleNum}} </span>
          <span style="color: #0b4eaa;font-size: 16px;margin-right: 20px;">累计金额:￥{{orderCustomerAmt.contractAmt}} </span>
          <span style="color: #0b4eaa;font-size: 16px">应收金额:￥{{orderCustomerAmt.amountReceivable}} <b style="color: #ad1540" v-if="orderCustomerAmt.overdueTotalAmt > 0">(逾期金额:￥{{orderCustomerAmt.overdueTotalAmt}})</b></span>
      </div>
    <a-table :scroll="{ x: 500 }" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <a v-if="record.salesmanId === null" @click="onReceiveShow(record)">接单</a>
        <!-- <a-divider v-if="record.salesmanId===null" type="vertical" /> -->
        <br />
        <a :href="url" @click="onUrl(record)" target="_blank">详情</a>
      </template>

      <template #vehicles="{ record }">
        <a-tooltip>
          <template #title>{{ record.vehicles }}</template>
          <div v-if="record.vehicles != null" class="vehicles">
            <div v-for="item in record.vehicles.split(',')" :key="item.id">
              {{ item }}
            </div>
          </div>
        </a-tooltip>
      </template>
      <!--      <template #customerName="{ record }">-->
      <!--        <a-space >-->
      <!--          {{record.customerName}}-->
      <!--          <a v-if="record.customerName===''" @click="customerNameShow = true , id=record.appletUserId"><div >未认证</div></a>-->
      <!--          <div class="edit" v-if="record.customerName!==''" @click="customerNameShow = true , id=record.appletUserId">[ 修改 ]</div>-->
      <!--        </a-space>-->
      <!--      </template>-->
    </a-table>

    <a-modal v-if="receiveShow" v-model:visible="receiveShow" width="600px" title="是否接单" @ok="onReceive"
      @cancel="onCustomerNameNo" :confirmLoading="receiveLoading">

      <a-form ref="formRef" :rules="rules" :model="receiveForm" layout="vertical">
        <a-form-item label="回访情况" name="orderLog">
          <a-textarea style="width: 552px" v-model:value="receiveForm.orderLog" :rows="4" :maxlength="300" showCount />
        </a-form-item>
        <a-form-item label="是否接单" name="orderLog">
          <a-select v-model:value="receiveForm.isReceive" :options="receiveEnum" placeholder="请选择" style="width: 100%">
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="企业名称" name="key">
          <a-select v-if="customerName === ''" v-model:value="receiveForm.key" placeholder="请输入" style="width: 100%" :filter-option="false" :show-arrow="false" size="large" @search="customerNameSearch" @change="onCustomerChange" show-search>
            <a-select-option v-for="item in customerNames" :key="item.customerId" :value="item.customerId">
              <span>{{ item.name }}</span>
              <span style="float:right;">{{ item.mobile }}</span>
            </a-select-option>
          </a-select>

          <a-input v-if="customerName !== ''" v-model:value="customerName" disabled />
        </a-form-item> -->
      </a-form>
    </a-modal>
    <!--    <a-modal v-if="customerNameShow" v-model:visible="customerNameShow" width="40%" title="企业认证"-->
    <!--             @ok="onCustomerNameOk" @cancel="onCustomerNameNo">-->
    <!--      <a-form ref="formRef" :rules="rules" :model="customerNameForm" layout="vertical">-->
    <!--        <a-form-item label="企业名称" name="key">-->
    <!--          <a-select v-model:value="customerNameForm.key" placeholder="请输入" style="width: 100%" :filter-option="false"-->
    <!--                    :show-arrow="false" size="large" @search="customerNameSearch" @change="onCustomerChange"-->
    <!--                    show-search>-->
    <!--            <a-select-option v-for="item in customerNames" :key="item.customerId" :value="item.customerId">-->
    <!--              <span>{{ item.name }}</span>-->
    <!--              <span style="float:right;">{{ item.mobile }}</span>-->
    <!--            </a-select-option>-->
    <!--          </a-select>-->
    <!--        </a-form-item>-->
    <!--      </a-form>-->
    <!--    </a-modal>-->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { page, receive, customerSalesStatistics } from '@/api/crmManagement/crmOrderList'
import { message } from 'ant-design-vue'
import { getPageList } from '@/api/crmManagement/cooperativeCustomers'
import { configCustomer } from '@/api/crmManagement/crmWechatUser'
// import { message } from 'ant-design-vue'

export default {
  props: {
    appletUserId: String,
    customerId: String
  },
  setup (props) {
    const store = useStore()
    const formRef = ref()
    const state = reactive({
      id: '',
      url: '',
      appletUserId: '',
      customerName: '',
      loading: false,
      receiveLoading: false,
      receiveShow: false,
      customerNameShow: false,
      listData: [],
      searchForm: {},
      customerNames: [],
      orderCustomerAmt: {},
      receiveForm: {},
      customerNameForm: {},
      columns: [
        {
          title: '订单号',
          dataIndex: 'id',
          align: 'center',
          width: '190px'
        },
        {
          title: '状态',
          dataIndex: 'orderStatus.label',
          align: 'center'
          // width: '120px'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          align: 'center'
          // width: '200px'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center'
          // width: '200px'
        },
        {
          title: '车辆信息',
          dataIndex: 'vehicles',
          align: 'center',
          slots: {
            customRender: 'vehicles'
          }
          // width: '230px'
        },
        {
          title: '订单来源',
          dataIndex: 'isInside.label',
          align: 'center'
        },
        {
          title: '台数',
          dataIndex: 'truckNum',
          align: 'center'
        },
        {
          title: '是否取车',
          dataIndex: 'isPlaceIn.label',
          align: 'center'
          // width: '100px'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label',
          align: 'center'
          // width: '100px'
        },
        {
          title: '优惠金额',
          dataIndex: 'couponAmt',
          align: 'center'
          // width: '100px'
        },
        {
          title: '总运费',
          dataIndex: 'contractAmt',
          align: 'center'
          // width: '100px'
        },
        {
          title: '预约日期',
          dataIndex: 'subscribeTime',
          align: 'center'
          // width: '160px'
        },
        {
          title: '下单日期',
          dataIndex: 'orderTime',
          align: 'center'
          // width: '160px'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center'
          // width: '120px'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
          // width: '120px'
        },
        // {
        //   title: '回访内容',
        //   dataIndex: 'visitLog',
        //   align: 'center'
        //   // width: '120px'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          fixed: 'right',
          width: '4%',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      receiveEnum: [
        {
          label: '取消接单',
          value: 0
        },
        {
          label: '接单',
          value: 1
        }
      ],
      orderStatusModes: [
        { label: '保存', value: 0 },
        { label: '已推送', value: 1 },
        { label: '处理中', value: 2 },
        { label: '完成', value: 9 }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        key: {
          required: true,
          message: '请填写承运商名称',
          trigger: 'blur'
        },
        orderLog: {
          required: true,
          message: '请填写回访情况',
          trigger: 'blur'
        }
      }
    })

    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      state.searchForm.startAddress = clearBlank(state.searchForm.startAddress)
      state.searchForm.endAddress = clearBlank(state.searchForm.endAddress)
      page({
        ...state.searchForm,
        customerId: props.customerId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    const customerStatistics = () => {
      customerSalesStatistics({
        ...state.searchForm,
        customerId: props.customerId
      }).then(res => {
        if (res.code === 10000) {
          state.orderCustomerAmt = res.data
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    // 接单
    const onReceive = () => {
      console.log(' state.receiveForm', state.receiveForm)
      formRef.value.validate().then(() => {
        state.receiveLoading = true
        receive({
          orderId: state.receiveForm.orderId,
          isReceive: state.receiveForm.isReceive,
          customerId: state.receiveForm.key,
          customerName: state.receiveForm.customerName,
          orderLog: state.receiveForm.orderLog
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            onCustomerNameNo()
          }
        }).catch(err => {
          console.log(err)
        })
          .finally(() => {
            state.receiveLoading = false
          })
      })
    }
    onMounted(() => {
      loadData()
      customerStatistics()
      // if (props.appletUserId) state.appletUserId = props.appletUserId, loadData()
    })
    // 获取企业客户
    const customerNameSearch = async val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          getPageList({
            name: val,
            type: 1
          }).then(res => {
            if (res.code === 10000) {
              state.customerNames = res.data.records
            } else {
              state.customerNames = []
            }
          })
        }, 1500)
      } else {
        state.customerNames = []
      }
    }
    // 认证企业
    const onCustomerNameOk = () => {
      formRef.value.validate().then(() => {
        configCustomer(
          {
            customerId: state.customerNameForm.key,
            customerName: state.customerNameForm.customerName,
            id: state.id
          }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onCustomerNameNo()
            loadData()
          }
        })
      })
    }
    // 认证企业关闭
    const onCustomerNameNo = () => {
      state.receiveShow = false
      state.receiveForm = {}
      state.id = ''
    }
    // 企业选择事件
    const onCustomerChange = (e, v) => {
      state.receiveForm.customerId = e
      state.receiveForm.customerName = v.children[0].children
    }
    // 打开接单
    const onReceiveShow = (e) => {
      state.receiveShow = true
      state.receiveForm.orderId = e.id
      state.receiveForm.appletUserId = e.appletUserId
      state.receiveForm.key = e.customerId
      state.receiveForm.customerName = e.customerName
      state.customerName = e.customerName
    }
    const onUrl = (record) => {
      const H = process.env.NODE_ENV === 'prod' ? 'https://' : 'http://'
      const systemDomain = store.getters.uat.filter(item => item.systemType.value === 1)[0].systemDomain
      state.url = H + systemDomain + '/#/orderDetail/' + record.id
    }
    const timeChange = (e, v) => {
      state.searchForm.orderTime = v
    }

    const clearBlank = (value) => {
      if (value) {
        value = value.replace(/\s/g, '')
      }
      return value
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      onReceive,
      onCustomerChange,
      onCustomerNameNo,
      customerStatistics,
      customerNameSearch,
      onCustomerNameOk,
      handleTableChange,
      onReceiveShow,
      onUrl,
      formRef,
      timeChange,
      clearBlank
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 5px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 5px !important;
}

@import './index.less';</style>
