<template>
  <div class="ant-hx-table">
    <!-- <div class="ant-hx-table-search">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="电话号码">
          <a-input v-model:value="searchForm.mobile" />
        </a-form-item>
        <a-form-item label="业务员">
          <a-input v-model:value="searchForm.salesman" />
        </a-form-item>
        <a-form-item label="公司名称">
          <a-input v-model:value="searchForm.customerName" />
        </a-form-item>
        <a-form-item label="是否跟进">
          <a-select allowClear v-model:value="searchForm.isFollow" :options="BooleanFlagEnum" placeholder="请选择跟进类型" style="width:200px" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </div> -->
    <a-card :bodyStyle="{'padding':'0px'}" ref="card">
      <a-list item-layout="vertical" size="large" :data-source="listData">
        <template #renderItem="{ item }">
          <a-list-item key="item.title">
            <!--          按钮-->
            <!-- <template #extra>
              <a-space direction="vertical">
                <a-button type="primary" v-if="item.isFollow===0" @click="onFollow(item)">确认跟进
                </a-button> -->
            <!--                <a-button type="primary" v-if="item.isFollow!==0&&item.salesmanId!==null&&item.customerId!==null" @click="customerId=item.customerId,detailShow =true ">编辑信息-->
            <!--                </a-button>-->
            <!-- </a-space>
            </template> -->
            <!--          标题-->
            <a-list-item-meta>
              <template #title>
                <div class="list-title-mobile">
                  <a-space>
                    <div>{{ item.mobile }}</div>
                    <a-tag color="red" v-if="item.isFollow===0">待跟进</a-tag>
                    <a-tag color="green" v-if="item.isFollow===1">已跟进</a-tag>
                    <a-tag color="blue" v-if="item.isFollow===2">已下单</a-tag>
                    <a-tag color="yellow">{{item.promoterType?item.promoterType.label:''}}</a-tag>
                  </a-space>
                </div>
              </template>
              <template #description>
                <a-row :gutter="[24,2]">
                  <a-col :span="4" class="list-time">{{ item.customerName }}</a-col>
                  <a-col :span="8" class="list-time">业务员：{{ item.salesman }}</a-col>
                  <a-col :span="8" class="list-time">
                    <span style="cursor: default">拉黑用户：</span>
                    <a-popconfirm :title="`你确定${item.isBlack ? '取消此用户的':'将此用户'}拉黑吗？`" @confirm="onIsBlack(item)">
                      <a-switch v-model:checked="item.isBlack" @click="item.isBlack=!item.isBlack" :checkedValue="1" :unCheckedValue="0" :disabled="true" />
                    </a-popconfirm>
                  </a-col>
                </a-row>
              </template>
              <template #avatar>
                <a-avatar :src="carImg" />
              </template>
            </a-list-item-meta>
            <div class="actions list-title-mobile">
              <a-row :gutter="[12,2]" class="col">
                <a-col :span="18">
                  {{ item.startAddress }} — {{ item.endAddress }}
                </a-col>
                <a-col :span="6">
                  {{ item.transTime }}
                </a-col>
                <a-col :span="5">
                  <div v-if="item.isPick!==null">取车：{{ item.isPick.label }}</div>
                </a-col>
                <a-col :span="5">
                  <div v-if="item.isTakeOut!==null">送车：{{ item.isTakeOut.label }}</div>
                </a-col>
                <a-col :span="24" class="list-remark">
                  {{ item.remark }}
                </a-col>
                <a-col :span="24" class="list-col-vehicle" v-if="item.vehicles!=null">
                  <div v-for="(vehicle,vid) in  item.vehicles" :key="vid">
                    <a-space v-if="vehicle.cost!==null" class="list-vehicle">
                      <div> {{ vehicle.brand }}</div>
                      <div> {{ vehicle.model }}</div>
                      <div> {{ vehicle.vinNo }}</div>
                      <div v-if="vehicle.cost!==null">{{ vehicle.cost }} 万</div>
                      <div class="list-remark"> {{ vehicle.isNormal === 1 ? '' : '无法开动' }}</div>
                    </a-space>
                  </div>
                </a-col>
                <a-col :span="24">
                </a-col>
                <a-col :span="24" class="list-time tc">
                  <a-space>
                    <div>
                      <FieldTimeOutlined />
                      最近询价时间 {{ item.createTime }}
                    </div>
                    <div>|</div>
                    <div @click="onInquiryNum(item)" class="list-time">
                      <div class="list-div-img">
                        <div class="list-img">
                          <img src="@/assets/images/Bend/select.png">
                        </div>
                        总询价次数<span class="inquiryNum">{{ item.inquiryNum }}</span>
                      </div>
                    </div>
                  </a-space>
                </a-col>
              </a-row>
            </div>
          </a-list-item>
        </template>
      </a-list>
      <a-modal v-if="customerNameShow" v-model:visible="customerNameShow" width="40%" footer="" title="企业认证" @ok="onCustomerNameOk" @cancel="onCustomerNameNo" :destroyOnClose="true">
        <a-form ref="formRef" :rules="rules" :model="customerNameForm" layout="vertical">
          <a-form-item label="企业名称" name="key">
            <a-select v-model:value="customerNameForm.key" placeholder="起码2个字开始搜索" style="width: 100%" :filter-option="false" :not-found-content="fetching ? '起码输入2个中文进行搜索' : '没有相关数据'" :show-arrow="false" size="large" @search="customerNameSearch" @change="onCustomerChange" show-search>
              <a-select-option v-for="item in customerNames" :key="item.customerId" :value="item.customerId">
                <span>{{ item.name }}</span>
                <span style="float:right;">{{ item.mobile }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <a-row :gutter="[24,6]">
          <!--          <a-col :span="12" @click="onAddCustomerShow"  style="display:flex;color:#0066cc;margin-top:15px;cursor:pointer;">-->
          <a-col :span="12" style="display:flex;color:#dddddd;margin-top:15px;">
            +新增企业
          </a-col>
          <a-col :span="12">
            <div style="display:flex;justify-content: flex-end;margin-top:15px">
              <a-button size="large" style="margin-right:15px" @click="onCustomerNameNo">取消</a-button>
              <a-button size="large" type="primary" @click="onCustomerNameOk">
                确认
              </a-button>
            </div>
          </a-col>
        </a-row>

      </a-modal>
      <a-modal v-model:visible="distributionShow" width="40%" title="领取客户" @ok="onCustomerOk">
        <p>确定要领取该客户吗？</p>
      </a-modal>
      <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose @close="closeDrawer">
        <CustomerDetail :customerId="customerId" type="1" @ok="closeDrawer" @updatePage="loadData" />
      </a-drawer>
      <a-drawer title="询价列表" width="80%" placement="right" v-model:visible="inquiryDetailShow" destroyOnClose @close="closeDrawer">
        <InquiryTable v-model:appletUserId="customerAppletUserId" @ok="closeDrawer" @updatePage="loadData" />
      </a-drawer>
      <div class="pagination" v-if="listData.length>0">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" show-size-changer :page-size="pagination.pageSize" :show-total="pagination.showTotal" @change="handleTableChange" @showSizeChange="handleTableChange">
          <template #buildOptionText="props">
            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            <span v-else>全部</span>
          </template>
        </a-pagination>
      </div>
    </a-card>
    <AddCustomer ref="addCustomer"></AddCustomer>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'

import { page, follow } from '@/api/crmManagement/crmInquiryList'
import { configCustomer } from '@/api/crmManagement/crmWechatUser'
import { message } from 'ant-design-vue'
import { getPageList } from '@/api/crmManagement/cooperativeCustomers'
import { FieldTimeOutlined } from '@ant-design/icons-vue'
import { userReceive } from '@/api/BEnd/appletPublic'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index'
import InquiryTable from './table'
import AddCustomer from './addCustomer'
import carImg from '@/assets/images/Bend/car.png'

export default {
  components: {
    FieldTimeOutlined,
    InquiryTable,
    CustomerDetail,
    AddCustomer
  },
  props: {
    appletUserId: String,
    customerId: String
  },
  setup (props) {
    const state = reactive({
      detailShow: false,
      customerShow: false,
      fetching: true,
      loading: false,
      distributionShow: false,
      inquiryDetailShow: false,
      empId: '',
      customerAppletUserId: '',
      customerId: '',
      listData: [],
      employeeArray: [],
      customerNames: [],
      customerNameForm: {},
      customerNameShow: false,
      searchForm: { mobile: '' },
      carImg: carImg,
      columns: [
        {
          title: '电话号码',
          dataIndex: 'mobile',
          align: 'center',
          width: '10%'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          align: 'center'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center'
        },
        {
          title: '车辆信息',
          align: 'center',
          slots: {
            customRender: 'vehicles'
          }
        },
        {
          title: '运车日期',
          dataIndex: 'createTime',
          align: 'center',
          width: '10%'
        },
        {
          title: '是否取车',
          dataIndex: 'isPick.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center',
          slots: {
            customRender: 'salesman'
          }
        },
        {
          title: '企业认证',
          dataIndex: 'customerName',
          align: 'center',
          slots: {
            customRender: 'customerName'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      BooleanFlagEnum: [
        {
          label: '已跟进',
          value: 1
        },
        {
          label: '待跟进',
          value: 0
        },
        {
          label: '已下单',
          value: 2
        }]
    })
    const addCustomer = ref()
    const onIsBlack = (item) => {
      item.isBlack = !item.isBlack
      if (item.isBlack) {
        state.listData[0].isBlack = 1
      } else {
        state.listData[0].isBlack = 0
      }

      // userIsBlack({
      //   id: e.id,
      //   isBlack: e.black
      // }).then(res => {
      //   if (res.code === 10000) {
      //     message.success(res.msg)
      //   }
      // })
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        appletUserId: props.appletUserId || 0,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          if (res.data.records.length > 0) {
            state.listData = res.data.records
          }
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters) => {
      state.pagination.current = pag
      state.pagination.pageSize = filters
      console.log(pag, filters)
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      console.log(props.appletUserId);
      if (props.appletUserId) {
        state.customerAppletUserId = props.appletUserId
      }
      loadData()
    })

    // 获取企业客户
    const customerNameSearch = async val => {
      state.fetching = false
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          getPageList({
            name: val,
            type: 1
          }).then(res => {
            if (res.code === 10000) {
              state.customerNames = res.data.records
              state.fetching = true
            } else {
              state.customerNames = []
            }
          })
        }, 500)
      } else {
        state.customerNames = []
      }
    }
    // 认证企业
    const onCustomerNameOk = () => {
      formRef.value.validate().then(() => {
        configCustomer(
          {
            customerId: state.customerNameForm.key,
            customerName: state.customerNameForm.customerId,
            id: state.customerAppletUserId
          }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onCustomerNameNo()
            loadData()
          }
        })
      })
    }
    // 认证企业关闭
    const onCustomerNameNo = () => {
      state.customerNameShow = false
      state.customerNameForm = {}
      state.id = ''
    }
    // 企业选择事件
    const onCustomerChange = (e, v) => {
      state.customerNameForm = v
      state.customerNameForm.customerId = v.children[0].children
    }
    // 员工被选择
    const onEmpIdChange = (e, v) => {
      state.empId = v.key
      console.log(' state.empId', state.empId)
    }
    const formRef = ref()
    const onCustomerOk = () => {
      // if (state.selectedRowKeys.length === 1) {
      userReceive({
        userId: [state.customerAppletUserId]
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.distributionShow = false
          loadData()
        }
      })
      state.distributionShow = false
    }
    const closeDrawer = () => {
      state.detailShow = false
      state.inquiryDetailShow = false
      loadData()
    }
    const onInquiryNum = (e) => {
      state.inquiryDetailShow = true
    }
    const onFollow = (e) => {
      console.log(e)
      follow({
        id: e.id
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          e.isFollow = 1
        }
      })
    }
    const onAddCustomerShow = () => {
      addCustomer.value.loadData()
    }

    return {
      ...toRefs(state),
      reset,
      onIsBlack,
      addCustomer,
      onAddCustomerShow,
      onSearch,
      loadData,
      handleTableChange,
      onEmpIdChange,
      onCustomerChange,
      onCustomerNameNo,
      customerNameSearch,
      formRef,
      onCustomerNameOk,
      onCustomerOk,
      closeDrawer,
      onInquiryNum,
      onFollow
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-btn) {
  background-color: '#f00' !important;
}
:deep(.ant-switch-checked) {
  background-color: #f00 !important;
}
:deep(.ant-switch-disabled) {
  cursor: default;
  background-color: #000;
}
:deep(.ant-col-8 > span) {
  cursor: pointer !important;
}
@import './index.less';
</style>
