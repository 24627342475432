import { postBodyRequest, getRequest } from '@/utils/axios'

export function getListData (customerId, params) {
  return postBodyRequest(`/market/crm/contract/${customerId}/page`, params)
}

export function deleteAct (contactId) {
  return postBodyRequest(`/market/crm/contract/${contactId}/delete`)
}

export function getCustomerDetail (customerId, params) {
  return getRequest(`/market/crm/contract/${customerId}`, params)
}
