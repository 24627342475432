<template>
  <div>
     <div class="flex ju-end p-b2 m-t1">
       <div @click="newlyAdd" class="addBtn flex al-center ju-center m-r4 cur-p"  style="color:#fff">
         <span>+新增</span>
       </div>
     </div>
     <div class="flex wrap ju-between" style="width: 100%;">
      <div v-for="item in listData" :key="item.id" style="width: 49.4%;" class="m-b2">
        <a-card :title="item.name" bodyStyle="padding: 0px;" headStyle="background: #E8F1FA;color: #344563;font-size: 16px;font-weight: 700" style="border: 1px solid #ABD2F6;">
        <template #extra><span :style="{'color':colorList[item.invoiceType.value]}" style="font-size: 16px;font-weight: 700;" v-if="item.invoiceType">{{ typeList[item.invoiceType.value] }}</span></template>
         <div class="flex wrap" style="padding: 20px;">
          <span style="width: 60%;" class="m-b1">名称：{{ item.name }}</span>
          <span class="m-b1" v-if="item.invoiceType.value==3">税号：{{ item.taxIdentNum }}</span>
          <span class="m-b1" v-if="item.invoiceType.value==2">组织机构代码：{{ item.taxIdentNum }}</span>
          <span class="m-b1" v-if="item.invoiceType.value==1">身份证号：{{ item.taxIdentNum }}</span>
          <span class="m-b1" style="width: 60%;">电话：{{ item.phone }}</span>
          <span class="m-b1">地址：{{ item.address }}</span>
          <span class="m-b1" style="width: 60%;">开户行：{{ item.bank }}</span>
          <span class="m-b1">银行账号：{{ item.account }}</span>
          <div style="width: 100%;height:50px" class="flex wrap">
            <span class="m-b1" style="width: 60%;">开票资料截图： <a v-if="item.screenshotIds.length > 0"  @click="lookImg('screenshotIds',item)">查看</a></span>
            <span class="m-b1" v-if="item.invoiceType.value==3" >一般纳税人资格证： <a v-if="item.taxpayerFileId" @click="lookImg('taxpayerFileId',item)">查看</a></span>
            <span  style="width: 60%;" v-if="item.invoiceType.value==3">营业执照： <a v-if="item.licenseFileId" @click="lookImg('licenseFileId',item)">查看</a></span>
            <span   v-if="item.invoiceType.value==2">组织机构代码证： <a v-if="item.licenseFileId"  @click="lookImg('licenseFileId',item)">查看</a></span>
          </div>
         </div>
          <div class="footBox flex al-center">
              <div style="width: 60%;padding:0 20px;color: #C1C7D0;font-size: 14px;">
                 {{ item.creator }}<span class="m-l1">{{ item.createTime }}</span>
              </div>
              <span>
                <a style="margin-right: 4px">默认发票</a>
                <a-switch :checked="item.isMain.value" @change="addSwitch(item)" :checkedValue="1" :unCheckedValue="0" size="small" />
                <!-- <a-popconfirm placement="top" overlayClassName="LY"  ok-text="确定" cancel-text="取消" @confirm="confirm">
                        <template #title>
                           确定要删除吗
                        </template> -->
                  <a  style="margin-right: 5px;" class="m-l2"> 启用</a>
                  <span class="m-r1"><a-switch v-model:checked="item.isEnable.value" @change="startStop(item)" :checkedValue="1" :unCheckedValue="0" size="small" /></span>
               <!-- </a-popconfirm> -->
               <a class="m-l2" @click="editInvoice(item)"> 编辑</a>
              </span>
          </div>
       </a-card>
     </div>
     </div>
     <FormModal width="32%" @getVal="getVal" ref="modalRef" :visible="visible" @cancel="cancel" title="新增发票抬头"  :fromList="fromList">
      <template #Header>
        <div>
          <a-form >
            <a-form-item label="" name="headerTypeId">
             <div style="margin-bottom:6px"><span style="color:red;font-family:SimSun, sans-serif">*</span> 抬头类型</div>
              <a-select v-model:value="headerTypeId" placeholder="请选择" @change="headerTypeChange">
                <a-select-option value="1">个人</a-select-option>
                <a-select-option value="2">政府单位</a-select-option>
                <a-select-option value="3">企业</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </div>
      </template>
      <template #custom>
        <div>
          <div v-if="headerTypeId==3">
            <span>一般纳税人资格证照片<span style="color:red">（如需开专票必须上传资格证照片）</span></span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.taxpayerImg"   :remove="handleRemove1" list-type="picture-card" :before-upload="beforeUpload">
                  <div v-if="formState.taxpayerImg.length < 1">
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div v-if="headerTypeId">
            <!-- 可多张 -->
            <span>开票资料上传（对方给的开票资料截图）</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.billingImg"   :remove="handleRemove2" list-type="picture-card" :before-upload="billingBeforeUpload">
                  <div>
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div  v-if="headerTypeId==3">
            <span>营业执照</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.businessLicenseImg"   :remove="handleRemove3" list-type="picture-card" :before-upload="businesBeforeUpload">
                  <div v-if="formState.businessLicenseImg.length<1" >
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div  v-if="headerTypeId==2">
            <span>组织机构代码证照片</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.institutionaImg"   :remove="handleRemove4" list-type="picture-card" :before-upload="institutionaBeforeUpload">
                  <div v-if="formState.institutionaImg.length < 1" >
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
        </div>
      </template>
     </FormModal>
     <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
     <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID" ></PictureViewer>
  </div>
</template>

<script setup>
import { ref,onMounted,reactive } from 'vue';
import FormModal from '@/components/formModal/index.vue'
import { CloseOutlined ,plusOutlined} from "@ant-design/icons-vue";
import { fileUpload as upload ,invoiceAdd,invoiceList,invoiceEdit,invoiceDetail ,invoiceIsMain,invoiceEnableType} from '@/api/marketing/modList'
import { message } from 'ant-design-vue';
import PictureViewer from '@/components/PictureViewer'
const props = defineProps({
  customerId:{
    type:String,
    required:true
  }
})
const typeList = {
  '1':'个人',
  '2':'政府单位',
  '3':'企业'
}
const colorList = {
  '1':'#EE7D10',
  '2':'#008005',
  '3':'#0066CC'
}
const isEdit = ref(false)
const visible = ref(false)
const visibleImgPic = ref(false)
const headerTypeId = ref(null)
const fromList = ref([])
const formState= reactive({
      taxpayerImg:[],//纳税人资格照片
      taxpayerImgList: [],//纳税人资格照片
      billingImg:[],//开票资料截图
      billingImgList:[],//开票资料截图
      businessLicenseImg:[],//营业执照
      businessLicenseImgList:[],//营业执照
      institutionaImg:[],//组织结构代码照片
      institutionaImgList:[],//组织结构代码照片
    });
const previewVisible = ref(false)
const previewImage = ref('')

//编辑发票
const editID = ref('')
const editInvoice = (item) => {
 disposeEcho(item.invoiceType.value,item)
}
//编辑处理数据
const disposeEcho = (e,msg) => {
  let list = []
  invoiceDetail(msg.id).then(res => {
    if(res.code !== 10000){
      message.error(res.msg)
      return
    }
    formState.billingImg = res.data.screenshot?res.data.screenshot:[]
    formState.billingImgList = res.data.screenshot?res.data.screenshot:[]
    if(e == 1) {
      list = [...personage.value ]
    }
    if(e == 2) {
      if(res.data.licenseFile){
        let imgList = []
        imgList.push(res.data.licenseFile)
        formState.institutionaImg = imgList
        formState.institutionaImgList = imgList
      }
      list = [...government.value ]
    }
    if(e == 3){
      if(res.data.taxpayerFile){
        let imgList = []
        imgList.push(res.data.taxpayerFile)
        formState.taxpayerImg = imgList
        formState.taxpayerImgList = imgList
      }
      if(res.data.licenseFile){
        let imgList1 = []
        imgList1.push(res.data.licenseFile)
        formState.businessLicenseImg = imgList1
        formState.businessLicenseImgList = imgList1
      }
      list = [...enterprise.value ]
    }
    list.forEach( item => {
     item.value = msg[item.prop]
    })
    fromList.value = list
    editID.value = msg.id
    isEdit.value = true
    headerTypeId.value = String(msg.invoiceType.value)
    visible.value = true
  })

}
//是否启用
const startStop = (item) => {
  invoiceEnableType(item.id,item.isEnable.value).then( res => {
    if(res.code == 10000){
      message.success('设置成功')
      reqInvoiceList()
    }
  })
}
//是否为主要
const addSwitch = ( item ) => {
  if(item.isMain.value == 1) {
    message.warn('已经是默认发票了，如需更改请点击其他发票')
    return
  }
  if(item.isMain.value == 0) {
    item.isMain.value = 1
    invoiceIsMain(props.customerId,item.id).then( res => {
    if(res.code == 10000){
      message.success('设置成功')
      reqInvoiceList()
    }
  })
  }
}
//预览图片
const lookImgID = ref([])
const lookImg = (type,item) => {
  let ids = item[type]
  if(typeof(ids) == 'string') {
    let id = []
    id.push(ids)
    lookImgID.value = id
  }else{
    lookImgID.value = ids
  }
 visibleImgPic.value = !visibleImgPic.value
}

//停用
const confirm = () => {

}
//确定按钮
const modalRef = ref(null)
const getVal = (val) => {
  if( isUpdateLoading.value) return
  if(!headerTypeId.value){
    message.warn('请选择抬头类型')
    return
  }
  if(headerTypeId.value != 3){
    if(!val.name){
      message.warn('请输入名称')
      return
    }
    if(val.taxIdentNum&&val.taxIdentNum.length !== 18){
      if(headerTypeId.value == 1){
        message.warn('身份证号格式不正确')
      }else{
         message.warn('组织机构代码格式不正确')
      }
      return
    }
      Object.assign(val, getImgUpdate());
      if(isEdit.value){
        reqInvoiceEdit(val)
      }else{
        reqInvoiceAdd(val)
      }
  }else{
    modalRef.value.formRef.validate()
        .then(() => {
          Object.assign(val, getImgUpdate());
          if(isEdit.value){
              reqInvoiceEdit(val)
          }else{
              reqInvoiceAdd(val)
          }
        })
        .catch((error) => {
         message.warn('请完善表单内容')
        });
  }

}
// 编辑账单
const reqInvoiceEdit = (msg) => {
  msg.invoiceType = Number( headerTypeId.value)
  msg.id = editID.value
  invoiceEdit(props.customerId,msg).then((res) => {
    if(res.code !== 10000) return
    message.success(res.msg)
    reqInvoiceList()
    visible.value = false
  });
}
//新增账单
const reqInvoiceAdd = (msg) => {
  msg.invoiceType = Number( headerTypeId.value)
  invoiceAdd(props.customerId,msg).then(res => {
    if(res.code !== 10000) return
    message.success(res.msg)
    reqInvoiceList()
    visible.value = false
  })
}
// 获取上传信息照片
const getImgUpdate = () => {
  let ids =  []
  formState.billingImgList.forEach(item => {
    ids.push(item.id)
  })
  let licenseFileId = ''
  if(formState.institutionaImgList.length>0){
    licenseFileId = formState.institutionaImgList[0].id
  }else if(formState.businessLicenseImgList.length>0){
    licenseFileId = formState.businessLicenseImgList[0].id
  }
  let arr = {
    taxpayerFileId:formState.taxpayerImgList.length>0?formState.taxpayerImgList[0].id:'', //一般纳税人资格照片
    screenshotIds:ids,//资料照片
    licenseFileId:licenseFileId,//组织结构照片||营业执照
  }
  return arr
}
//纳税人图片上传
 const beforeUpload = (file) => {
  updateBatch(file,1)
  return false
}
//开票资料照片
const billingBeforeUpload = (file) => {
  updateBatch(file,2)
  return false
}
// 营业资格照片
const businesBeforeUpload = (file) => {
  updateBatch(file,3)
  return false
}
// 组织结构照片
const institutionaBeforeUpload = (file) => {
  updateBatch(file,4)
  return false
}
const key = 'updatable';
const isUpdateLoading = ref(false)
// 图片上传type==1为纳税人照片、2为开票资料照片、3为营业资格照片、4为组织结构照片
const updateBatch = (file,type) => {
    isUpdateLoading.value = true
    message.loading({ content: '上传中..', key });
    const formData = new FormData()
    formData.append('file', file)
    upload(formData).then((res) => {
      isUpdateLoading.value = false
      if (res.code === 10000) {
        if(type == 1){
          formState.taxpayerImgList.push(res.data)
        }
        if(type == 2){
          formState.billingImgList.push(res.data)
        }
        if(type == 3){
          formState.businessLicenseImgList.push(res.data)
        }
        if(type == 4){
          formState.institutionaImgList.push(res.data)
        }
        message.success({ content: '上传成功', key, duration: 2 });
      }else{
        message.error({ content: '上传失败', key, duration: 2 });
      }
    })
}
//预览图片
const handlePreview =  async(file) => {
  if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj))
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
};
  // 图片移除
  const handleRemove1 = (file) => {
    if(file.originFileObj) {
      formState.taxpayerImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.taxpayerImgList.splice(index, 1)
      })
    }else{
      formState.taxpayerImgList.forEach((item, index) => {
        if (item.id === file.id) formState.taxpayerImgList.splice(index, 1)
      })
    }

  }
  const handleRemove2 = (file) => {
    if(file.originFileObj){
      formState.billingImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.billingImgList.splice(index, 1)
      })
    }else{
       formState.billingImgList.forEach((item, index) => {
        if (item.id === file.id) formState.billingImgList.splice(index, 1)
      })
    }

  }
  const handleRemove3 = (file) => {
    if(file.originFileObj) {
      formState.businessLicenseImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.businessLicenseImgList.splice(index, 1)
      })
    }else{
      formState.businessLicenseImgList.forEach((item, index) => {
        if (item.id === file.id) formState.businessLicenseImgList.splice(index, 1)
      })
    }

  }
  const handleRemove4 = (file) => {
    if(file.originFileObj) {
      formState.institutionaImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.institutionaImgList.splice(index, 1)
      })
    }else{
      formState.institutionaImgList.forEach((item, index) => {
        if (item.id === file.id) formState.institutionaImgList.splice(index, 1)
      })
    }

  }

//选择抬头类型
const headerTypeChange = (e) => {
  modalRef.value.formRef.clearValidate()
  for(let i in formState){
    formState[i] = []
  }
  if(e == 1) {
   fromList.value = [...personage.value ]
  }
  if(e == 2) {
  fromList.value = [...government.value ]
  }
  if(e == 3){
  fromList.value = [...enterprise.value ]
  }
  fromList.value.forEach( item => {
    item.value = ''
   })
}

const newlyAdd = () => {
  isEdit.value = false
  headerTypeId.value = null
  fromList.value = []
  visible.value = !visible.value
}
const cancel = () => {
  for(let i in formState){
    formState[i] = []
  }
  visible.value = false
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const reqInvoiceList = () => {
  invoiceList(props.customerId,{}).then(res => {
    if(res.code !== 10000) return
    listData.value = res.data

  })
}
const listData = ref([])
//企业
const enterprise = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "请输入名称", trigger: "blur" }],
  },
  {
    label: "税号",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: true, message: "请输入税号", trigger: "blur" },{ min: 18, max: 18, message: '税号格式不正确', trigger: 'blur' },],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: true, message: "请输入电话", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: true, message: "请输入地址", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: true, message: "请输入开户行", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: true, message: "请输入银行账号", trigger: "blur" }],
  },
]
)
//个人
const personage = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "身份证号",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
])
//政府单位
const government = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "组织机构代码",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
])
onMounted(() => {
  reqInvoiceList()
})
</script>

<style lang="less" scoped>
.footBox{
  border-top: 1px solid #E1EDF9;
  height: 50px;
}

.addBtn{
  width: 75px;
  height: 30px;
  background: #1890FF;
  border-radius: 2px;
}
:global(.ant-popover-inner-content) {
        padding: 12px 16px;
      }
</style>
