<template>
  <div style="display: flex; justify-content: space-between">
    <div class="new-score">
      <div style="display: flex; justify-content: space-between">
        <h3 class="title-content" v-show="detailData.rateId">
          <span>{{ detailData.grader }}</span
          >&emsp;
          <span>{{ detailData.updateTime }}</span>
        </h3>
        <div style="flex: 1; text-align: right">
          <a-button class="addGrade-btn" @click="addGrade">+新增打分</a-button>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr class="table-head">
            <td>类别</td>
            <td>指标</td>
            <td>得分</td>
            <td>备注</td>
            <!-- <td>合计</td> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in tableArray" :key="i">
            <td><b>{{ item.rateType?.label }}</b></td>
            <td>{{ item.indexContent }}</td>
            <td>
              <span v-if="item.rateType?.value === 1">{{ item.score }}</span>
              <span v-show="!contentShow && item.rateType?.value !== 1">{{
                item.score
              }}</span>
              <a-input-number
                v-if="item.rateType?.value !== 1"
                v-show="contentShow"
                placeholder="请填写"
                v-model:value="item.score"
                :min="0" :max="item.max"
                @change="changeScore"
              />
            </td>
            <td>
              <a-tooltip>
                <template #title>{{ item.remark }}</template>
                <div v-show="!contentShow" class="remark-content">
                  <span>{{ item.remark }}</span>
                </div>
              </a-tooltip>
              <a-textarea v-show="contentShow" v-model:value="item.remark" />
            </td>
            <!-- <td>X</td> -->
          </tr>
          <tr>
            <td><b>总分</b></td>
            <td>{{ totalScore }}</td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex; justify-content: flex-end; margin-top: 10px">
        <a-button type="primary" v-show="addShow" :loading="loading" @click="submitData"
          >提交分数</a-button
        >
      </div>
    </div>
    <div style="flex: 1">
      <div class="history-score">
        <h3 class="title-content">历史评分记录</h3>
        <div class="history-content">
          <div v-for="(item, index) in historylist" :key="item.rateId">
            <div
              style="display: flex; justify-content: space-between; align-items: center"
            >
              <div style="display: flex; align-items: center">
                <div style="width: 100px">得分：{{ item.score }}</div>
                <div style="display: flex">{{ item.level?.label }}</div>
              </div>
              <img
                src="@/assets/images/u143.png"
                style="width: 20px; height: 20px; cursor: pointer"
                @click="lookDetail(item, index)"
              />
            </div>
            <div style="color: #acadb0; font-size: 14px; font-weight: normal">
              <span style="margin-right: 30px">{{ item.grader }}</span>
              <span>{{ item.updateTime }}</span>
            </div>
            <a-divider />
          </div>
          <!-- <h2 v-show="historylist.length <= 0" style="text-align: center;">暂无历史打分记录</h2> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs, watch} from 'vue'
import {
  getPreviousData,
  historyPage,
  saveData,
  getDetailData
} from '@/api/dataCenter/kanban.js'
import { message } from 'ant-design-vue'

export default {
  props: {
    customerId: String
  },
  setup (props) {
    const state = reactive({
      tableArray: [],
      historylist: [],
      detailData: {},
      rateId: '',
      index: null,
      totalScore: 0,
      isNew: false,
      addShow: false,
      editShow: false,
      loading: false,
      contentShow: false
    })
    const loadTable = () => {
      state.index = null
      state.addShow = false
      state.editShow = false
      state.contentShow = false
      historyData()
    }
    const historyData = () => {
      historyPage({
        customerId: props.customerId,
        gradeType: 2,
        current: 1,
        size: 20
      }).then((res) => {
        if (res.code === 10000) {
          state.historylist = res.data.records
          if (state.historylist.length > 0) {
            if (!state.isNew) {
              lookDetail(state.historylist[0], 0)
            }
          } else {
            getPreviousData(props.customerId, 2).then((res) => {
              if (res.code === 10000) {
                state.tableArray = res.data
                changeScore()
              }
            })
          }
        }
      })
    }
    const addGrade = () => {
      state.contentShow = true
      getPreviousData(props.customerId, 2).then((res) => {
        if (res.code === 10000) {
          state.tableArray = res.data
          state.addShow = true
          changeScore()
        }
      })
    }
    const changeScore = () => {
      state.totalScore = 0
      state.tableArray.forEach((item) => {
        state.totalScore += Number(item.score)
      })
    }
    const submitData = () => {
      let total = 0
      state.tableArray.forEach((item) => {
        total += item.score
      })
      if (total * 1 >= 0) {
        saveData({
          customerId: props.customerId,
          gradeType: 2,
          rateDetailList: state.tableArray,
          score: total
        }).then((res) => {
          if (res.code === 10000) {
            state.isNew = true
            loadTable()
            message.success('保存成功')
          }
        })
      } else message.error('总分不能小于0，请检查')
    }
    const editData = () => {
      state.editShow = true
      state.contentShow = true
    }
    const confirmEdit = () => {
      submitData()
    }
    const lookDetail = (item, index) => {
      if (state.rateId !== item.rateId) {
        state.index = index
        state.addShow = false
        state.editShow = false
        state.contentShow = false
        state.rateId = item.rateId
        state.detailData = item
        getDetailData(item.rateId).then((res) => {
          if (res.code === 10000) {
            state.tableArray = res.data
            changeScore()
          }
        })
      }
    }
    return {
      ...toRefs(state),
      loadTable,
      addGrade,
      editData,
      changeScore,
      confirmEdit,
      lookDetail,
      historyData,
      submitData
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
