<template>
  <a-row :gutter="[24, 24]">
    <a-col :span="12" class="search">
      <h1>基本信息</h1>
    </a-col>
    <a-col :span="12" class="search">
      <div class="end" style="display:flex;justify-content: flex-end;">
        <a-space align="end">
          <a-button v-if="disabled" @click="onDisabled()">编辑</a-button>
          <a-button v-else class="searchLoading-button" @click="onEdit()">保存</a-button>
        </a-space>
      </div>
    </a-col>
  </a-row>
  <a-form layout="vertical" :model="customerDetail"
          style="display:flex;flex-wrap: wrap;justify-content: space-between;">
    <a-form-item label="录入归属" style="width:30%;">
      <a-input v-model:value="customerDetail.saleName" disabled/>
    </a-form-item>
    <a-form-item label="负责客服" style="width:30%;">
      <a-input v-model:value="customerDetail.saleName" disabled/>
    </a-form-item>
    <a-form-item label="客户类型" style="width:30%;">
      <a-select allowClear v-model:value="customerDetail.customerType.value" class="ransport_search_input"
                :disabled="disabled" :options="$store.state.enumAll.CustomerTypeEnum"/>
    </a-form-item>
    <!-- <a-form-item label="业务类型" style="width:47%;">
      <a-select allowClear v-model:value="customerDetail.businessType.value" class="ransport_search_input"
        :disabled="disabled" :options="$store.state.enumAll.CustomerBusinessTypeEnum" />
    </a-form-item> -->
    <a-form-item :label="customerDetail.customerType.value === 1 ? '客户名称' : '企业名称'" style="width:30%;">
      <a-input v-show="customerDetail.customerType?.value === 1" v-model:value="customerDetail.name" placeholder="请填写"
               :disabled="disabled"/>
      <div v-show="customerDetail.customerType?.value === 2">
        <a-input v-if="disabled" v-model:value="customerDetail.name" placeholder="请填写" :disabled="disabled"/>
        <a-select v-if="!disabled" v-model:value="customerDetail.name" placeholder="搜索并选择客户" @search="fetchCarrier" :filterOption="false"
                  show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small"/>
          </template>
          <a-select-option v-for="item in enterpriseList" :key="item.id" @click="enterpriseChoose(item)">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

    </a-form-item>
    <a-form-item :label="customerDetail.customerType.value === 1 ? '身份证号' : '信用代码'" placeholder="请填写"
                 style="width:30%;">
      <a-input v-model:value="customerDetail.certificateNumber" :disabled="disabled"/>
    </a-form-item>
    <a-form-item label="客户电话" style="width:30%;">
      <a-input v-model:value="customerDetail.mobile" :disabled="disabled"/>
    </a-form-item>
    <a-form-item label="微信号" style="width:30%;">
      <a-input v-model:value="customerDetail.wechat" placeholder="请填写" :disabled="disabled"/>
    </a-form-item>
    <a-form-item label="客户来源" style="width:30%;">
      <div style="display: flex;">
        <template
          v-if="customerSour && customerDetail.customerSourceType && customerDetail.customerSourceType.value !== 5">
          {{ customerDetail.customerSourceType?.label }}
        </template>
        <a-select v-show="!customerSour" allowClear
                  :disabled="disabled"
                  @change="customerSourChange"
                  v-model:value="customerDetail.customerSourceType.value" :options="$store.state.enumAll.customerSource"
                  placeholder="选择客户来源">
        </a-select>
        <div v-show="!customerSour && customerDetail.customerSourceType?.value === 4 && customerDetail.channelName">
          <a-input v-model:value="customerDetail.channelName" disabled/>
        </div>
        <a-select
          v-if="customerDetail.customerSourceType?.value === 2"
          v-model:value="customerDetail.sourcePerson" :disabled="disabled"
          :filter-option="false" show-search placeholder="请输入员工姓名" @search="employerValue">
          <template v-if="flagLoading" #notFoundContent>
            <a-spin size="small"/>
          </template>
          <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
                           @click="addEmployeeChoose(item)">{{ item.name }}-{{ item.orgName }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="customerDetail.customerSourceType?.value === 3" placeholder="搜索并选择客户"
          v-model:value="customerDetail.introduceCustomerName" :disabled="disabled"
          @search="addEntpSearch" :filterOption="false" show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small"/>
          </template>
          <a-select-option v-for="item in enterprises" :key="item.name" :value="item.id" @click="enterpriseEdit(item)">
            {{ item.name }}—{{ item.mobile }}
          </a-select-option>
        </a-select>
      </div>
    </a-form-item>
    <a-form-item label="结算方式" style="width:30%;">
      <a-input v-model:value="customerDetail.settlementMode.label" disabled/>
    </a-form-item>
    <a-form-item label="线索分类" style="width:30%;">
      <a-select allowClear v-model:value="customerDetail.clueType.value" class="ransport_search_input" disabled
                :options="$store.state.enumAll.ClueTypeEnum"/>
    </a-form-item>
    <a-form-item label="关联承运商" style="width:30%;">
      <a-select v-if="!disabled" :disabled="disabled" v-model:value="customerDetail.carrierId" placeholder="请至少输入2个汉字"
                style="width: 100%" :filter-option="false" :show-arrow="false" @search="carrierAutoComplete"
                show-search>
        <a-select-option v-for="item in carriers" :key="item.id" :value="item.id">
          <span>{{ item.name }}</span>
        </a-select-option>
      </a-select>
      <a-input v-else :disabled="disabled" v-model:value="customerDetail.carrierName"/>
    </a-form-item>
    <a-form-item label="是否共享" style="width:30%;">
      <a-select v-model:value="customerDetail.share" allowClear class="ransport_search_input" :disabled="disabled"
                :options="$store.state.enumAll.BooleanFlagEnum"/>
    </a-form-item>
    <!-- <a-form-item label="渠道来源" style="width:30%;">
      <a-input disabled v-model:value="customerDetail.channelName" />
    </a-form-item> -->
    <a-form-item label="常用地址" style="width:30%;">
      <a-input v-model:value="customerDetail.address" placeholder="请填写" :disabled="disabled"/>
    </a-form-item>
    <!-- <a-form-item label="客户来源" style="width:30%;">
      <a-select v-model:value="customerDetail.tagId" allowClear class="ransport_search_input" placeholder="请选择"
        :disabled="disabled" :options="$store.state.enumAll.CustomerType" />
    </a-form-item> -->
    <a-form-item label="备注" style="width:100%">
      <a-textarea placeholder="备注" v-model:value="customerDetail.remark" :maxlength="200" :disabled="disabled"/>
    </a-form-item>
  </a-form>
</template>

<script>
import { fuzzy } from '@/api/crm/carrer/carrier'
import { enterprise } from '@/api/system/setting'
import { reactive, toRefs, onMounted } from 'vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { detail, edit } from '@/api/crmManagement/customer'
import { pageAll } from '@/api/crm/customer'
import { message } from 'ant-design-vue'

export default {
  props: {
    customerId: String,
    type: Number
  },

  setup (props, context) {
    const state = reactive({
      cId: props.customerId,
      type: props.type,
      carriers: [],
      customerSource: [],
      customerSourceTypeLabel: '',
      customerSourceTypeInit: undefined,
      enterpriseList: [],
      employeeOptions: [],
      enterprises: [],
      disabled: true,
      fetching: false,
      flagLoading: false,
      timeId: null,
      customerDisabled: true,
      customerSour: false,
      timeOut: null,
      customerDetail: {
        channelName: '',
        businessType: {},
        clueType: {},
        customerType: {},
        settlementMode: {},
        customerSourceType: { label: '', value: 0 }
      }
    })
    const addEntpSearch = val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          pageAll({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data.records
              state.timeOut = null
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    // 客户详情
    const loadData = () => {
      detail(state.cId).then(res => {
        if (res.code === 10000) {
          state.customerDetail = res.data
          if (state.customerDetail.customerSourceName !== '' && state.customerDetail.customerSourceName !== null) state.customerSour = true
          if (state.customerDetail.clueType === null) state.customerDetail.clueType = { value: '' }
          state.customerDetail.channe = [res.data.channelId, res.data.channelSubId]
          if (state.customerDetail.customerSourceType === null) {
            state.customerDetail.customerSourceType = {}
          } else {
            state.customerSourceTypeLabel = state.customerDetail.customerSourceType.label
            state.customerSourceTypeInit = state.customerDetail.customerSourceType.value
          }
        }
      })
    }
    // 编辑详情
    const onEdit = () => {
      edit({ customer: state.customerDetail }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
          state.disabled = true
        }
      })
    }
    // 打开禁用
    const onDisabled = () => {
      state.disabled = false
      if (state.type !== 2) {
        state.customerDisabled = false
      }
    }
    onMounted(() => {
      loadData()
    })
    // 员工搜索
    const employerValue = (e) => {
      if (e === '' || e === null) clearTimeout(state.timeId), (state.fetching = false)
      state.flagLoading = true
      if (state.timeId !== null) {
        clearTimeout(state.timeId)
      }
      state.timeId = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then((res) => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch((err) => {
          console.log(err)
        })
      }, 1000)
    }
    const addEmployeeChoose = item => {
      state.customerDetail.sourcePerson = item.name
      state.customerDetail.sourcePersonId = item.id
    }
    const setChannel = (value, selectedOptions) => {
      state.customerDetail.channelId = value[0]
      if (value.length > 1) {
        state.customerDetail.channelSubId = value[1]
      }
    }
    // 客户来源 改变事件
    const customerSourChange = (e) => {
      if (state.customerSourceTypeInit === 4) {
        message.error('无法将' + state.customerSourceTypeLabel + '修改为其他类型')
        setTimeout(() => {
          state.customerDetail.customerSourceType = { label: state.customerSourceTypeLabel, value: state.customerSourceTypeInit }
        }, 10)
      } else if (e === 4) {
        message.error('无法将其他类型修改为此：网络引流')
        setTimeout(() => {
          state.customerDetail.customerSourceType = { label: state.customerSourceTypeLabel, value: state.customerSourceTypeInit }
        }, 10)
      } else {
      }
    }
    // 搜索供应商
    const carrierAutoComplete = val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          fuzzy({
            name: val,
            type: 2
          }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else {
              state.carriers = []
            }
          })
        }, 500)
      } else {
        state.carriers = []
      }
    }
    // 搜索企业
    const fetchCarrier = value => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          enterprise({ name: value }).then(res => {
            if (res.code === 10000) {
              state.enterpriseList = res.data
            } else state.enterpriseList = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.enterpriseList = []
      }
    }
    // 选择企业
    const enterpriseChoose = item => {
      state.customerDetail.name = item.name
    }
    const enterpriseEdit = item => {
      state.customerDetail.introduceCustomerName = item.name
      state.customerDetail.introduceCustomerId = item.id
    }
    return {
      ...toRefs(state),
      loadData,
      onEdit,
      addEntpSearch,
      employerValue,
      setChannel,
      addEmployeeChoose,
      customerSourChange,
      carrierAutoComplete,
      onDisabled,
      fetchCarrier,
      enterpriseEdit,
      enterpriseChoose
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-btn) {
  // background-color: #f0f2f5;
  border: none;
}

// @import '../../tool/index.less';
@import './index.less';
</style>
