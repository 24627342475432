import { postBodyRequest, getRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/market/crm/clue/page', params)
}

export function add (params) {
  return postBodyRequest('/market/crm/clue/addClue', params)
}

export function clueCustomerIdPage (customerId, params) {
  return postBodyRequest(`/market/crm/clue/${customerId}/page`, params)
}
export function getClueInfo (params) {
  return getRequest('/market/crm/clue/getClueInfo', params)
}
export function edit (params) {
  return postBodyRequest('/market/crm/clue/editClueInfo', params)
}
