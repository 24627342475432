import { getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/market/crm/private/page', params)
}
// export function call (params) {
//   return postBodyRequest('/market/crm/public/receive/call', params)
// }
export function detail (customerId) {
  return getRequest(`/market/crm/private/${customerId}`)
}
export function add (params) {
  return postBodyRequest('/market/crm/private/add', params)
}
export function dropPublic (params) {
  return postBodyRequest('/market/crm/private/drop/public', params)
}
export function transfer (params) {
  return postBodyRequest('/market/crm/private/transfer', params)
}
export function tag (params) {
  return postBodyRequest('/market/crm/private/tag', params)
}
