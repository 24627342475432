<template>
  <div v-show="!loading" class="detail">
    <div class="detail-head">
      <div class="detail-head-left">
        <div v-if="customerDetail.customerType">
          [{{ customerDetail.customerType.label }}]
          <span v-if="customerDetail.name === ''">无</span>{{ customerDetail.name }}
        </div>
<!--        <div v-show="customerDetail.tagName !== ''">-->
<!--          <a-tag color="pink">{{ customerDetail.tagName }}</a-tag>-->
<!--        </div>-->
        <div>
          <a-tag color="#E96B00" v-if="customerDetail?.customerSourceType">{{ customerDetail?.customerSourceType?.label }}</a-tag>
          <a-tag color="#E96B00" v-if="customerDetail?.intentionLevel">{{ customerDetail?.intentionLevel?.label }}</a-tag>
          <template v-if="customerDetail.customerClass?.value === 3">
            <a-tag color="#E96B00" v-if="customerDetail?.coopType">成交-{{ customerDetail?.coopType?.label }}</a-tag>
            <a-tag color="#E96B00" v-if="customerDetail?.coopOldType">合作-{{ customerDetail?.coopOldType?.label }}</a-tag>
            <a-tag color="#D43030" v-if="customerDetail?.riskGrade">风险-{{ customerDetail?.riskGrade?.label }}</a-tag>
            <a-tag color="#003470" v-if="customerDetail?.vehicleGrade">台量-{{ customerDetail?.vehicleGrade?.label }}</a-tag>
            <a-tag color="#AC33C1" v-if="customerDetail?.qualityGrade">质量-{{ customerDetail?.qualityGrade?.label }}</a-tag>
          </template>
        </div>
        <div>
          <a-tag color="#009587"  class="m-t1" v-for="(item, index) in tagsData" :key="index">
            {{item.tagType?.label}}
          </a-tag>
          <a-tag @click="addOpenShow">+添加标签</a-tag>
        </div>
      </div>
      <div class="detail-head-right">
        <a-space align="end">
          <a-button @click="on" class="tool-color-344563" v-if="type === '2'">
            <icon-font type="hy-baifangjihua" class="tool-icon" /> 拜访计划
          </a-button>
          <a-button
            @click="transactionStatusShow = true"
            class="tool-color-344563"
            v-if="type === '2'"
          >
            <icon-font type="hy-jiesuanfangshishezhi" class="tool-icon" /> 更改结算方式
          </a-button>
          <!-- <a-button @click="tagShow = true" class="tool-color-344563">
            <icon-font type="hy-xiugai" class="tool-icon" /> 修改标签
          </a-button> -->
          <a-button @click="transferShow = true">
            <template #icon>
              <SwapOutlined style="width: 18px; height: 18px" />
            </template>
            移交客户
          </a-button>
          <a-button @click="dropPShow = true" v-if="type !== '2'">
            <icon-font type="hy-a-ziyuan1" class="tool-icon" />放回临时客户
          </a-button>
          <a-button @click="backPrivateShow = true" v-if="type === '2'">
            <icon-font type="hy-a-ziyuan1" class="tool-icon" />退回私海客户
          </a-button>
          <a-button @click="blacklistShow = true" v-if="type === '2'">
            <template #icon>
              <StopOutlined style="width: 18px; height: 18px" />
            </template>
            <span>{{
              customerDetail.isBlack && customerDetail.isBlack.value === 0
                ? "拉黑客户"
                : "取消拉黑"
            }}</span>
          </a-button>
          <a-button @click="transactionStatusShow = true" v-if="type !== '2'">
            <template #icon> <CheckCircleOutlined /> </template>更改成交状态
          </a-button>
        </a-space>
        <a-modal v-model:visible="blacklistShow" width="500px" @ok="blacklist">
          <template #title>
            <div>
              确认<span>{{
                customerDetail.isBlack && customerDetail.isBlack.value === 0
                  ? "拉黑客户"
                  : "取消拉黑"
              }}</span>
            </div>
          </template>
          <!-- <a-form-item label="拉黑">
            <a-select v-model:value="transactionStatusFrom.isBlack" class="ransport_search_input" placeholder="请选择" :options="$store.state.enumAll.blacklist" />
          </a-form-item> -->
          <a-form-item label="原因">
            <a-textarea
              v-model:value="transactionStatusFrom.remark"
              placeholder="请填写备注/原因"
              :rows="4"
            />
          </a-form-item>
        </a-modal>
        <a-modal
          v-model:visible="transactionStatusShow"
          width="500px"
          :title="type === '2' ? '更改结算方式' : '更改成交状态'"
          @ok="onTransactionStatus"
        >
          <a-form layout="vertical" :model="transactionStatusFrom">
            <a-form-item label="结算方式">
              <a-select
                v-model:value="transactionStatusFrom.settlementMode"
                class="ransport_search_input"
                :options="CustomerSettlementEnum"
              >
              </a-select>
            </a-form-item>
          </a-form>
          <div v-if="transactionStatusFrom.settlementMode === 1">
            确认该客户为实时结算？
          </div>
          <div v-if="transactionStatusFrom.settlementMode > 1">
            <a-form
              layout="vertical"
              ref="formRef"
              :model="transactionStatusFrom"
              :rules="rules"
            >
              <a-form-item label="合同编号" name="code">
                <a-input v-model:value="transactionStatusFrom.code" />
              </a-form-item>
              <a-form-item label="合同名称" name="name">
                <a-input v-model:value="transactionStatusFrom.name" />
              </a-form-item>
              <a-form-item label="开始结束时间" name="time">
                <a-range-picker
                  @change="timeChange"
                  v-model:value="transactionStatusFrom.time"
                  :show-time="{ format: 'HH:mm' }"
                  valueFormat="YYYY-MM-DD"
                  :placeholder="['起始时间', '截止时间']"
                  style="width: 100%"
                />
              </a-form-item>
              <a-form-item label="签约人" name="signer">
                <a-select
                  v-model:value="transactionStatusFrom.signer"
                  class="ransport_search_input"
                  show-search
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @select="handleSelect"
                  @search="handleSearch"
                >
                  <a-select-option
                    v-for="item in employeeArray"
                    :value="item.name"
                    :key="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item
                :label="
                  transactionStatusFrom.settlementMode == 2   
                    ? '结算周期/月(请输入数字)'
                    : '结算周期/天(请输入数字)'
                "
              >
                <a-input v-model:value="transactionStatusFrom.settlementCycle" type="number" placeholder="请输入数字" />
              </a-form-item>
              <a-form-item name="image">
                <template #label>
                  <div><span style="color: #f00">* </span>合同图片</div>
                </template>
                <div v-if="fileList.length > 0">
                  <a-button :loading="imgLoading" @click="handleChange()"
                    >请点击批量保存</a-button
                  >
                </div>
              </a-form-item>
              <a-upload
                multiple
                v-model::file-list="fileList"
                :remove="handleRemove"
                list-type="picture-card"
                :before-upload="beforeUpload"
              >
                <div>
                  <plus-outlined></plus-outlined>
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </a-form>
          </div>
        </a-modal>
        <a-modal
          v-model:visible="dropPShow"
          width="500px"
          title="放回临时客户"
          @ok="onDropPublic"
        >
          <a-form layout="vertical" ref="formRef" :model="formState">
            <a-form-item>
              <template #label>
                <div>放回理由<span style="color: #f00">*</span></div>
              </template>
              <a-select
                :options="$store.state.enumAll.DropPublicEnum"
                @change="reasonChange"
                class="ransport_search_input"
              />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-model:value="dropPForm.remark" :maxlength="200" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal
          v-model:visible="backPrivateShow"
          width="500px"
          title="放回私海客户"
          @ok="onBackPrivate"
          destroyOnClose
        >
          <a-form layout="vertical" ref="formRef">
            <a-form-item label="备注">
              <a-textarea v-model:value="dropPForm.reason" :maxlength="200" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal
          v-model:visible="transferShow"
          width="500px"
          title="移交客户"
          @ok="onTransfer"
        >
          <a-form layout="vertical" ref="formRef" :model="formState">
            <a-form-item>
              <template #label>
                <div>移交负责人为<span style="color: #f00">*</span></div>
              </template>
              <a-select
                v-model:value="transferForm.empAfterName"
                class="ransport_search_input"
                show-search
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @select="transferHandleSelect"
                @search="handleSearch"
              >
                <a-select-option
                  v-for="item in employeeArray"
                  :value="item.name"
                  :key="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-model:value="transferForm.remark" :maxlength="200" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal v-model:visible="tagShow" width="500px" title="修改标签" @ok="onTag">
          <a-radio-group v-model:value="tagForm.tagId" button-style="solid">
            <a-space>
              <a-radio-button
                v-for="item in $store.state.enumAll.CustomerLabelType"
                :key="item.value"
                :value="item.value"
                >{{ item.label }}</a-radio-button
              >
            </a-space>
          </a-radio-group>
        </a-modal>
        <a-modal
          v-model:visible="addTagShow"
          width="40%"
          title="添加标签"
          footer=""
          @cancel="closeAddShow"
        >
          <div>
            <div>选择添加符合该客户的标签</div>
            <div style="display: flex; flex-wrap: wrap">
              <div
                class="tags-div"
                v-for="(item, index) in labelAllList"
                :key="index"
                @click="addTag(item, index)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </a-modal>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-body-w25">
        <div class="detail-body-w25-t">线索来源</div>
        <div class="detail-body-w25-b">
          {{ customerDetail.clueType ? customerDetail.clueType.label : "" }}
        </div>
      </div>
      <div class="detail-body-w25">
        <div class="detail-body-w25-t">
          {{ customerDetail.customerType.value === 1 ? "身份证号" : "企业信用代码" }}
        </div>
        <div class="detail-body-w25-b">{{ customerDetail.certificateNumber }}</div>
      </div>
      <!-- <div class="detail-body-w25">
        <div class="detail-body-w25-t">业务类型</div>
        <div class="detail-body-w25-b">{{customerDetail.businessType.label}}</div>
      </div> -->
      <div class="detail-body-w25">
        <div class="detail-body-w25-t">创建时间</div>
        <div class="detail-body-w25-b">{{ customerDetail.createTime }}</div>
      </div>
      <div class="detail-body-w25">
        <div class="detail-body-w25-t">联系方式</div>
        <div class="detail-body-w25-b">{{ customerDetail.mobile }}</div>
      </div>
    </div>
    <div class="detail-bottom">
      <div class="detail-bottom-tabs">
        <a-tabs v-model:activeKey="activeKey" @change="componsLoad">
          <a-tab-pane key="1" tab="线索信息">
            <Clue :customerId="cId" v-if="activeKey === '1'" />
            <FollowAdd :customerId="cId" v-if="activeKey === '1'" />
          </a-tab-pane>
          <!-- <a-tab-pane key="2" tab="跟进记录">
            <FollowAdd :customerId="cId" v-if="activeKey==='2'" />
          </a-tab-pane> -->
          <a-tab-pane key="3" tab="对接人">
            <Contact :customerId="cId" v-if="activeKey === '3'" />
          </a-tab-pane>
          <a-tab-pane v-if="type === '2'" key="4" tab="业务员">
            <Salesman :customerId="cId" v-if="activeKey === '4'" />
          </a-tab-pane>
          <a-tab-pane v-if="type === '2'" key="5" tab="合同">
            <Contract :customerId="cId" v-if="activeKey === '5'" />
          </a-tab-pane>
          <a-tab-pane key="6" tab="详细资料">
            <CustomerDetail
              :customerId="cId"
              :type="type === '2' ? 2 : 0"
              v-if="activeKey === '6'"
            />
          </a-tab-pane>
          <a-tab-pane key="7" tab="流转日志">
            <Circulation v-if="activeKey === '7'" :customerId="cId" />
          </a-tab-pane>
          <a-tab-pane key="8" tab="订单列表">
            <OrderListC
              v-if="activeKey === '8'"
              :appletUserId="appletUserId"
              :customerId="cId"
            />
          </a-tab-pane>
          <a-tab-pane key="9" tab="询价信息">
            <InquiryC
              :appletUserId="appletUserId"
              :customerId="cId"
              v-if="activeKey === '9'"
            />
          </a-tab-pane>
          <a-tab-pane
            :key="10"
            tab="通联账号"
            v-if="
              customerDetail.customerClass && customerDetail.customerClass.value === 3
            "
          >
            <BindMembers
              :carrierId="cId"
              :carrierNatureType="customerDetail.customerType.value"
              ref="_bindMembers"
              v-show="activeKey === 10"
            />
          </a-tab-pane>
          <a-tab-pane v-if="type == 2" :key="11" tab="质量等级">
            <Quality :customerId="cId" ref="_quality" />
          </a-tab-pane>
          <a-tab-pane v-if="type == 2" :key="12" tab="风险等级">
            <RiskScore :customerId="cId" ref="_riskScore" />
          </a-tab-pane>
          <a-tab-pane   :key="13" tab="发票抬头">
           <InvoiceTitle :customerId="cId" v-if="activeKey == '13'" ></InvoiceTitle>
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- <div class="detail-bottom-abstract" v-if="type==='2'">
        <h2 class="detail-bottom-abstract-title">客户摘要</h2>
        <p>跟进次数： 6次</p>
        <p>未跟时长： 52天</p>
        <p>掉落次数： 2次</p>
        <p>创建时间： 2022-03-01 18:39</p>
      </div> -->
    </div>
  </div>
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    v-show="loading"
  >
    <a-spin size="large" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import Circulation from '../circulation/index.vue'
import Clue from '../clue/index.vue'
import Contact from '../contact/index.vue'
import Contract from '../contract'
import FollowAdd from '../customerFollow/add.vue'
import CustomerDetail from './detail.vue'
import Salesman from '../salesman/index.vue'
import OrderListC from '../orderListC/index.vue'
import InquiryC from '../inquiryC/index.vue'
import BindMembers from '../../../carrier/toolDetail/bindMembers/index.vue'
import Quality from '../quality/index.vue'
import RiskScore from '../riskScore/index.vue'
import InvoiceTitle from '../invoiceTitle/index.vue'
import { confirmWindow } from '@/utils/util'
import {
  getCustomerLabelAll,
  addCustomerLabel,
  getLabelAll
} from '@/api/crmManagement/follow'
import {
  detail,
  dropPublic,
  transfer,
  tag as editTag
} from '@/api/crmManagement/privateSea'
import {
  contractAdd,
  settlementTypeChange,
  blackCustomer
} from '@/api/crmManagement/tool'
import {
  editCustomerTag,
  backPrivate,
  cooperTransfer
} from '@/api/crmManagement/cooperativeCustomers'
import { list } from '@/api/system/employee'
import {
  createFromIconfontCN,
  SwapOutlined,
  StopOutlined,
  CheckCircleOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { fileUpload as upload } from '@/api/marketing/modList'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default {
  props: {
    customerId: String,
    type: Number,
    num: String
  },
  components: {
    IconFont,
    Circulation,
    SwapOutlined,
    StopOutlined,
    CheckCircleOutlined,
    Clue,
    Contact,
    Salesman,
    Contract,
    FollowAdd,
    CustomerDetail,
    OrderListC,
    BindMembers,
    InquiryC,
    Quality,
    RiskScore,
    InvoiceTitle
  },
  setup (props, context) {
    const _riskScore = ref()
    const _quality = ref()
    const formRef = ref()
    const store = useStore()
    const _bindMembers = ref()
    const state = reactive({
      // activeKey: props.num !== '' && props.num !== undefined && props.num !== undefined ? props.num : '1',
      activeKey: '1',
      loading: false,
      fetching: true,
      addTagShow: false,
      blacklistShow: false,
      imgLoading: false,
      settlementShow: false,
      disabled: true,
      cId: props.customerId,
      type: null,
      transactionStatusShow: false,
      dropPShow: false,
      backPrivateShow: false,
      transferShow: false,
      tagShow: false,
      appletUserId: '',
      customerId: '',
      flagArr: [],
      tagsData: [],
      labelAllList: [],
      dropPForm: {
        customerId: props.customerId
      },
      transferForm: {
        customerId: props.customerId
      },
      tagForm: {
        customerId: props.customerId
      },
      customerDetail: {
        businessType: {},
        clueType: {},
        customerType: {},
        settlementMode: {}
      },
      rules: {
        code: { required: true, message: '请填写合同编号', trigger: 'blur' },
        name: { required: true, message: '请填写合同名称', trigger: 'blur' },
        time: {
          required: true,
          message: '请选择始末时间',
          trigger: 'change',
          type: 'array'
        },
        signer: { required: true, message: '请选择签约人', trigger: 'blur' }
      },
      employeeArray: [],
      CustomerSettlementEnum: [],
      fileList: [],
      transactionStatusFrom: {
        fileIds: [],
        settlementMode: null
      }
    })
    // 客户详情
    const loadData = () => {
      state.loading = true
      getCustomerTags()
      detail(state.cId).then((res) => {
        if (res.code === 10000) {
          state.customerDetail = res.data
          state.appletUserId = res.data.appletUserId
          state.tagForm.tagId = state.customerDetail.tagId
          state.loading = false
        }
      })
    }
    onMounted(() => {
      if (props.customerId) state.cId = props.customerId
      if (props.type) state.type = props.type
      state.CustomerSettlementEnum = JSON.parse(
        JSON.stringify(store.state.enumAll.CustomerSettlementEnum)
      )
      if (
        state.CustomerSettlementEnum[0].value === '' ||
        state.CustomerSettlementEnum[0].value === 0
      ) { state.CustomerSettlementEnum.splice(0, 1) }
      loadData()
    })
    const reasonChange = (e, v) => {
      state.dropPForm.reason = v.label
    }
    const getTagsData = () => {
      getCustomerLabelAll(state.cId).then((res) => {
        if (res.code === 10000) {
          state.tagsData = res.data
        }
      })
    }
    const getCustomerTags = () => {
      getLabelAll().then((res) => {
        if (res.code === 10000) {
          state.labelAllList = res.data.CUSTOMER_LABEL
          getTagsData()
        }
      })
    }
    // 退回公海
    const onDropPublic = () => {
      if (state.dropPForm.reason !== undefined && state.dropPForm.reason !== null) {
        dropPublic(state.dropPForm).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.dropPShow = false
          }
        })
      } else message.error('放回理由不能为空')
    }
    // 退回私海客户
    const onBackPrivate = () => {
      backPrivate(state.dropPForm).then((res) => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.backPrivateShow = false
        }
      })
    }
    // 移交客户
    const onTransfer = () => {
      if (
        state.transferForm.empAfterName !== undefined &&
        state.transferForm.empAfterName !== ''
      ) {
        if (state.type === '2') {
          cooperTransfer(state.transferForm).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.transferShow = false
            }
          })
        } else {
          transfer(state.transferForm).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.transferShow = false
            }
          })
        }
      } else message.error('移交负责人不能为空')
    }
    // 修改标签
    const onTag = () => {
      if (state.type === '2') {
        editCustomerTag(state.tagForm).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.tagShow = false
            emit('updatePage')
            loadData()
          }
        })
      } else if (state.type === '1') {
        editTag(state.tagForm).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.tagShow = false
            emit('updatePage')
            loadData()
          }
        })
      }
    }
    // 拉黑客户
    const blacklist = () => {
      // if (state.transactionStatusFrom.isBlack !== '' && state.transactionStatusFrom.isBlack !== null && state.transactionStatusFrom.isBlack !== undefined) {
      blackCustomer({
        customerId: state.cId,
        isBlack:
          state.customerDetail.isBlack && state.customerDetail.isBlack.value === 0
            ? { label: '拉黑客户', value: 1 }
            : { label: '取消拉黑', value: 0 },
        remark: state.transactionStatusFrom.remark
      })
        .then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.blacklistShow = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
      // } else message.error('请先选择是否拉黑')
    }
    // 客户转移 赋值
    const transferHandleSelect = (value, option) => {
      state.transferForm.empAfterId = option.key
      state.transferForm.empAfterName = value
    }
    const settlementChange = (e, v) => {
      state.transactionStatusFrom.settlementMode = v
    }
    // 更改为成交状态
    const onTransactionStatus = () => {
      state.transactionStatusFrom.customerId = state.cId
      if (state.transactionStatusFrom.settlementMode !== 1) {
        formRef.value
          .validate()
          .then(() => {
            const fileArr = document.querySelectorAll(
              '.ant-upload-list-picture-card-container'
            )
            if (fileArr.length > 0 && state.fileList.length === 0) {
              if (state.type === '2') {
                let  transactionStatusFrom = JSON.parse(JSON.stringify(state.transactionStatusFrom))
                let arr = []
                if (transactionStatusFrom.fileIds[0]?.id) {
                  transactionStatusFrom.fileIds.forEach((item) => {
                    if (item.id) arr.push(item.id)
                    else arr.push(item)
                  })
                  transactionStatusFrom.fileIds = arr
                }
                // else if (state.transactionStatusFrom.fileIds === arr) arr = state.transactionStatusFrom.fileIds
                settlementTypeChange(transactionStatusFrom).then((res) => {
                  if (res.code === 10000) {
                    message.success(res.msg)
                    state.transactionStatusShow = false
                    state.transactionStatusFrom.fileIds = []
                    emit('ok')
                  }
                })
              } else {
                let arr = state.transactionStatusFrom.fileIds
                if (state.transactionStatusFrom.fileIds.some((item) => item.id)) {
                  arr = []
                  state.transactionStatusFrom.fileIds.forEach((item) => {
                    if (item.id) arr.push(item.id)
                    else arr.push(item)
                  })
                  state.transactionStatusFrom.fileIds = arr
                }
                // else if (state.transactionStatusFrom.fileIds === arr) arr = state.transactionStatusFrom.fileIds
                contractAdd(state.transactionStatusFrom).then((res) => {
                  if (res.code === 10000) {
                    message.success(res.msg)
                    state.transactionStatusShow = false
                    state.transactionStatusFrom.fileIds = []
                    emit('ok')
                  }
                })
              }
            } else message.error('请上传至少一张合同图片')
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        if (state.type === '2') {
          settlementTypeChange(state.transactionStatusFrom).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.transactionStatusShow = false
              state.transactionStatusFrom.fileIds = []
              emit('ok')
            }
          })
        } else {
          contractAdd(state.transactionStatusFrom).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.transactionStatusShow = false
              state.transactionStatusFrom.fileIds = []
              emit('ok')
            }
          })
        }
      }
    }
    // 签约人 赋值
    const handleSelect = (value, option) => {
      state.transactionStatusFrom.signId = option.key
      state.transactionStatusFrom.signer = value
    }
    // 员工搜索
    const handleSearch = (value) => {
      const reg = /^[A-Za-z]+$/
      if (value !== '') {
        if (!reg.test(value)) {
          state.fetching = false
          list({ name: value }).then((res) => {
            if (res.code === 10000) {
              state.employeeArray = res.data
              state.fetching = true
            }
          })
        }
      }
    }
    // 图片上传
    const handleChange = () => {
      // state.transactionStatusFrom.fileIds = []
      const len = state.fileList.length
      state.imgLoading = true
      updateBatch(0, len, state.fileList)
    }
    // 图片移除
    const handleRemove = (file) => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
      state.transactionStatusFrom.fileIds.forEach((item, index) => {
        if (item.name === file.originFileObj.name) {
          state.transactionStatusFrom.fileIds.splice(index, 1)
        }
      })
    }
    // 选中图片
    const beforeUpload = (file) => {
      state.fileList.push(file)
      return false
    }

    // 图片上传
    const updateBatch = (index, len, imageList) => {
      if (index <= len - 1) {
        const formData = new FormData()
        formData.append('file', imageList[index])
        upload(formData).then((res) => {
          if (res.code === 10000) {
            state.transactionStatusFrom.fileIds.push(res.data)
            // data.fileUrl = res.data.previewUrl
            if (index === len - 1) {
              setTimeout(() => {
                state.fileList = []
                message.success('图片上传成功')
                state.imgLoading = false
              }, 1000)
            } else {
              updateBatch(++index, len, imageList)
            }
          }
        })
      } else {
        return false
      }
    }

    const timeChange = (e, v) => {
      state.transactionStatusFrom.startTime = v[0]
      state.transactionStatusFrom.endTime = v[1]
    }
    const componsLoad = (e) => {
      setTimeout(() => {
        switch (e) {
          case 10:
            _bindMembers.value.loadData()
            break
          case 11:
            _quality.value.loadTable()
            break
          case 12:
            _riskScore.value.loadTable()
            break
        }
      }, 1)
    }
    const closeAddShow = () => {
      const DOMS = document.querySelectorAll('.tags-div')
      DOMS.forEach((item) => {
        item.style.background = '#EAF0FB'
        item.style.color = '#0066CC'
      })
    }
    const addTag = (item, index) => {
      let status = null
      state.flagArr.forEach((item) => {
        if (item === index) status = true
      })
      if (!status) {
        confirmWindow('确认添加 【' + item.label + '】 标签', require)
        const DOMS = document.querySelectorAll('.tags-div')
        function require (resolve) {
          addCustomerLabel({
            customerId: state.cId,
            tagType: item
          }).then((res) => {
            if (res.code === 10000) {
              message.success('添加' + item.label + '成功')
              DOMS[index].style.background = '#0066CC'
              DOMS[index].style.color = '#fff'
              state.tagsData.push({
                tagType: item
              })
            }
          }).finally(() => {
            resolve()
          })
        }
      }
    }
    const addOpenShow = () => {
      state.addTagShow = true
      setTimeout(() => {
        state.flagArr = []
        state.labelAllList.forEach((item, index) => {
          for (let i = 0; i < state.tagsData.length; i++) {
            if (item.value === state.tagsData[i].tagType.value) state.flagArr.push(index)
          }
        })
        const DOMS = document.querySelectorAll('.tags-div')
        state.flagArr.forEach((item) => {
          DOMS[item].style.background = '#0066CC'
          DOMS[item].style.color = '#fff'
        })
      }, 100)
    }
    return {
      ...toRefs(state),
      _quality,
      _riskScore,
      loadData,
      formRef,
      addTag,
      addOpenShow,
      closeAddShow,
      onTransactionStatus,
      blacklist,
      handleSelect,
      settlementChange,
      handleSearch,
      handleChange,
      handleRemove,
      beforeUpload,
      onDropPublic,
      reasonChange,
      onTransfer,
      onBackPrivate,
      transferHandleSelect,
      onTag,
      updateBatch,
      componsLoad,
      _bindMembers,
      timeChange
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-btn) {
  // background-color: #f0f2f5;
  border: none;
}

@import "../../tool/index.less";
@import "./index.less";
</style>
