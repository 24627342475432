<template>
  <div class="ant-hx-table-search">
    <a-form :model="searchForm" layout="inline">
      <a-form-item label="是否跟进">
        <a-select allowClear v-model:value="searchForm.isFollow" :options="BooleanFlagEnum" placeholder="请选择跟进类型" style="width:200px" />
      </a-form-item>
      <a-space>
        <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
          查询
        </a-button>
        <a-button @click="reset">
          重置
        </a-button>
      </a-space>
    </a-form>
  </div>

  <a-table :scroll="{ x: 1500, y: 700 }" size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
    <template #vehicles="{ record }">
      <div v-if="record.vehicles!=null" class="vehicles">
        <div v-for="item in record.vehicles" :key="item.id">
          <a-row>
            <a-col :span="11" v-if="item.brand!==''&&item.model!==''">
              {{ item.brand }}-{{ item.model }}
            </a-col>
            <a-col :span="7">
              {{ item.vinNo}}
            </a-col>
            <a-col :span="6" v-if="item.cost!==''&&item.cost!==null">
              {{ item.cost }}万
            </a-col>
            <!--              <a-col :span="8" v-if="item.num!==''">-->
            <!--                数量 {{ item.num }}-->
            <!--              </a-col>-->
          </a-row>
        </div>
      </div>
    </template>
    <template #isFollow="{ record }">
      <a-tag color="red" v-if="record.isFollow===0">待跟进</a-tag>
      <a-tag color="green" v-if="record.isFollow===1">已跟进</a-tag>
      <a-tag color="blue" v-if="record.isFollow===2">已下单</a-tag>
    </template>
  </a-table>

</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { detailsPage } from '@/api/crmManagement/crmInquiryList'

export default {
  props: {
    appletUserId: String
  },
  setup (props, { emit }) {
    const state = reactive({
      appId: props.appletUserId,
      loading: false,
      listData: [],
      searchForm: { mobile: '' },
      columns: [
        {
          title: '询价状态',
          dataIndex: 'isFollow',
          align: 'center',
          slots: {
            customRender: 'isFollow'
          },
          width: '10%'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          align: 'center'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center'
        },
        {
          title: '车辆信息',
          align: 'center',
          slots: {
            customRender: 'vehicles'
          },
          width: '250px'
        },
        {
          title: '运车日期',
          dataIndex: 'transTime',
          align: 'center',
          width: '10%'
        },
        {
          title: '询价金额',
          dataIndex: 'amt',
          align: 'center'
        },
        {
          title: '询价时间',
          dataIndex: 'createTime',
          align: 'center',
          width: '10%'
        },
        {
          title: '是否取车',
          dataIndex: 'isPick.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label',
          align: 'center',
          width: '5%'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      BooleanFlagEnum: [
        {
          label: '待跟进',
          value: 0
        },
        {
          label: '已跟进',
          value: 1
        },
        {
          label: '已下单',
          value: 2
        }]
    })

    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      detailsPage({
        appletUserId: state.appId,
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      loadData()
    })

    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
