<template>
  <div>
    <div style="display:flex;justify-content: flex-end;">
      <a-button @click="addShow = true">+ 新增业务员</a-button>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <div style="display:flex;justify-content: space-between;padding:0 10px;">
          <span>
            {{record.createTime}}
          </span>
          <span class="delete-btn">
            <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">
              <a class="delete-data" style="color:#f00">删除</a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
    <a-modal v-model:visible="addShow" title="增加业务员" @ok="addSalesman">
      业务员姓名：
      <a-select style="width:150px" v-model:value="transferForm.empAfterName" show-search :filter-option="false" :not-found-content="fetching ? undefined : null" @select="transferHandleSelect" @search="handleSearch">
        <a-select-option v-for="item  in employeeArray" :value="item.name" :key="item.id">{{item.name}}</a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getListData, add, deleteSalesman } from '@/api/crmManagement/salesman'
import { list } from '@/api/system/employee'
import { message } from 'ant-design-vue'
export default {
  props: {
    customerId: String
  },
  setup (props) {
    const state = reactive({
      loading: false,
      addShow: false,
      fetching: true,
      customerId: '',
      listData: [],
      employeeArray: [],
      columns: [
        {
          title: '对接业务员',
          dataIndex: 'empName'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      transferForm: {
        customerId: props.customerId
      },
      pagination: {
        current: 1,
        pageSizeOptions: ['5', '10', '15'],
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.loading = true
      getListData(state.customerId, {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
        }
      }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    onMounted(() => {
      state.customerId = props.customerId
      loadData()
    })
    // 员工搜索
    const handleSearch = value => {
      const reg = /^[A-Za-z]+$/
      if (value !== '') {
        if (!reg.test(value)) {
          state.fetching = false
          list({ name: value }).then((res) => {
            if (res.code === 10000) {
              state.employeeArray = res.data
              state.fetching = true
            }
          })
        }
      }
    }
    const transferHandleSelect = (value, option) => {
      state.transferForm.empId = option.key
      state.transferForm.empName = value
    }
    const addSalesman = () => {
      add(state.transferForm)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addShow = false
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    const deleteData = record => {
      deleteSalesman(record.id)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    return {
      ...toRefs(state),
      transferHandleSelect,
      addSalesman,
      handleSearch,
      deleteData,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
